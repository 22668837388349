'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Linkholder.scss');

var Linkholder = function Linkholder(params) {
  return _react2.default.createElement(
    _reactRouter.Link,
    { to: params.to, className: styles.linkholder, style: params.style },
    _react2.default.createElement(
      'h1',
      { className: styles.text },
      params.text
    ),
    params.button && _react2.default.createElement(
      'button',
      { className: styles.button },
      params.button
    )
  );
};

exports.default = Linkholder;
module.exports = exports['default'];