"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require("babel-runtime/core-js/object/get-own-property-descriptor");

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require("babel-runtime/core-js/object/get-prototype-of");

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn");

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require("babel-runtime/helpers/inherits");

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _components = require("components");

var _shortLink = require("redux/modules/shortLink");

var _Allow = require("../Allow/Allow");

var _Allow2 = _interopRequireDefault(_Allow);

var _reactCopyToClipboard = require("react-copy-to-clipboard");

var _reactCopyToClipboard2 = _interopRequireDefault(_reactCopyToClipboard);

var _reactHelmet = require("react-helmet");

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _translations = require("translations");

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require("core-decorators");

var _reactRedux = require("react-redux");

var _themeEnabled = require("helpers/themeEnabled");

var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require("../../helpers/reachHistory");

var _sessionPermissions = require("../../helpers/sessionPermissions");

var _ShortLinkDetail = require("./ShortLinkDetail.scss");

var _ShortLinkDetail2 = _interopRequireDefault(_ShortLinkDetail);

var _ShortLinkList = require("./ShortLinkList.scss");

var _ShortLinkList2 = _interopRequireDefault(_ShortLinkList);

var _useBasename = require("../../helpers/useBasename");

var _useBasename2 = _interopRequireDefault(_useBasename);

var _numberFormat = require("../../helpers/numberFormat");

var _numberFormat2 = _interopRequireDefault(_numberFormat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    "div",
    null,
    props.children
  );
};
(0, _moment2.default)().locale('es');

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back'),
  edit: require('react-icons/lib/md/create'),
  delete: require('react-icons/lib/md/delete'),
  clipboard: require('react-icons/lib/md/content-copy'),
  refresh: require('react-icons/lib/md/refresh')
};

var ShortLinkDetail = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    shortlink: state.shortlink.list,
    user: state.auth.data.user
  };
}, {
  get: _shortLink.getShortLinks,
  updateShortLinkStatus: _shortLink.updateShortLinkStatus,
  getShortLinkStats: _shortLink.getShortLinkStats,
  getShortLinkReport: _shortLink.getShortLinkReport,
  getShortLinkFiles: _shortLink.getShortLinkFiles,
  push: _reachHistory.push
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(ShortLinkDetail, _React$Component);

  function ShortLinkDetail(props) {
    (0, _classCallCheck3.default)(this, ShortLinkDetail);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ShortLinkDetail.__proto__ || (0, _getPrototypeOf2.default)(ShortLinkDetail)).call(this, props));

    _this.handleInformation = function () {
      var link_uid = _this.props.params.short_link_uid;

      var params = { url_id: link_uid };
      _this.getShortLinkStats(params);
      _this.handleFiles(params);
    };

    _this.statusTranslations = {
      'ACTIVE': (0, _translations2.default)('URL ACTIVE'),
      'INACTIVE': (0, _translations2.default)('URL INACTIVE')
    };
    if (_this.props.shortlink.length === 0) _this.props.get();
    _this.state = {
      data: null,
      stats: null,
      sms_link: {},
      created_on: '',
      created_by: '',
      current_date: '',
      values_for_graphic: [],
      browser_for_graphic: [],
      os_for_graphic: [],
      files: [],
      tags_for_graphic: []
    };
    return _this;
  }

  (0, _createClass3.default)(ShortLinkDetail, [{
    key: "getShortLinkReport",
    value: function getShortLinkReport(startDate, endDate, shortLink) {
      var _this2 = this;

      this.props.getShortLinkReport(startDate, endDate, shortLink).then(function (data) {
        var sms_sent = data.result.aggregations.sms_shortlink_sent.doc_count;
        var total_sms = data.result.hits.total;
        _this2.setState({
          sms_link: {
            sms_sent: sms_sent,
            total_sms: total_sms
          }
        });
      });
    }
  }, {
    key: "getShortLinkStats",
    value: function getShortLinkStats(params) {
      var _this3 = this;

      this.props.getShortLinkStats(params).then(function (response) {
        _this3.setState({
          data: response[0],
          stats: response[0],
          created_on: response[0].created_on,
          created_by: response[0].created_by,
          current_date: (0, _moment2.default)(new Date()).format('YYYY-MM-DD')
        });
        var created_on = response[0].created_on;
        var created_by = response[0].created_by;
        var current = (0, _moment2.default)(new Date()).format('YYYY-MM-DD');
        var stats = response[0].information;
        _this3.getShortLinkReport(Date.parse(created_on), (0, _moment2.default)().valueOf(), response[0].short_url);
        _this3.handleStats(created_on, current, stats);
      });
    }
  }, {
    key: "datesList",
    value: function datesList(created_on, current_date) {
      created_on = (0, _moment2.default)(created_on).add(-1, 'day').format('YYYY-MM-DD');
      var date_array = [{ date: created_on }];

      while (Date.parse(created_on) <= Date.parse(current_date)) {
        created_on = (0, _moment2.default)(created_on).add(1, 'day').format('YYYY-MM-DD');
        date_array.push({ date: created_on });
      }
      return date_array;
    }
  }, {
    key: "handleStats",
    value: function handleStats(created_on, current_date, stats) {
      created_on = (0, _moment2.default)(created_on).format('YYYY-MM-DD');
      var list = this.datesList(created_on, current_date);
      var information = stats.map(function (values) {
        var tempdate = (0, _moment2.default)(values.visit_date).format('YYYY-MM-DD');
        values.date = tempdate;
        values.os == 'undefined' ? values.os = 'otros' : values.os;
        values.browser == undefined ? values.browser = 'otros' : values.browser;
        return values;
      });

      var date_param = {};
      information.forEach(function (elem) {
        date_param[elem.date] = (date_param[elem.date] || 0) + 1;
      });

      var dates = list.map(function (el) {
        el.visits = date_param[el.date];
        el.visits ? el.visits : el.visits = 0;
        return el;
      });

      var dates_for_graphic = [];
      dates.map(function (date) {
        dates_for_graphic.push([Date.parse(date.date), date.visits]);
      });

      var os = {};
      information.forEach(function (elem) {
        os[elem.os] = (os[elem.os] || 0) + 1;
      });
      var os_for_graphic = [];
      for (var value in os) {
        os_for_graphic.push({ name: value, y: os[value] });
      }

      var browser = {};
      information.forEach(function (elem) {
        browser[elem.browser] = (browser[elem.browser] || 0) + 1;
      });
      var browser_for_graphic = [];
      for (var _value2 in browser) {
        browser_for_graphic.push({ name: _value2, y: browser[_value2] });
      }
      this.setState({
        values_for_graphic: dates_for_graphic,
        browser_for_graphic: browser_for_graphic,
        os_for_graphic: os_for_graphic
      });
      //count = Object.keys(result).length
      return { dates_for_graphic: dates_for_graphic, browser_for_graphic: browser_for_graphic, os_for_graphic: os_for_graphic };
    }
  }, {
    key: "handleFiles",
    value: function handleFiles(params) {
      var _this4 = this;

      this.props.getShortLinkFiles(params).then(function (files) {
        var tags = [];
        tags = files.map(function (file) {
          var file_date = (0, _moment2.default)(file.created_on).format('YYYY-MM-DD');
          var date = Date.parse(file_date);

          return { x: date, title: file.name, text: file.meta.message };
        });
        _this4.setState({
          files: files,
          tags_for_graphic: tags
        });
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.handleInformation();
    }
  }, {
    key: "pathDeleteLink",
    value: function pathDeleteLink() {
      this.props.push({
        pathname: '/success',
        state: {
          goTo: '/short_link/',
          message: (0, _translations2.default)('LINK DELETED'),
          modal: true,
          returnTo: '/short_link/'
        }
      });
    }
  }, {
    key: "handleDeleteLink",
    value: function handleDeleteLink(param) {
      var _this5 = this;

      var params = {};
      params.status = param;
      params.id = this.state.data._id;

      if (confirm('Desea Desactivar el link corto?')) {
        this.props.updateShortLinkStatus(params).then(function (result) {
          if (result) {
            _this5.pathDeleteLink();
          }
        });
      }
    }
  }, {
    key: "handleCampaign",
    value: function handleCampaign() {
      var link_uid = this.state.data._id;
      this.props.push({
        pathname: "/short_link/" + link_uid + "/addFile",
        data: 'holi'
      });
    }
  }, {
    key: "handleCopyLink",
    value: function handleCopyLink(link) {
      alert('Link copiado a portapapeles');
    }
  }, {
    key: "reloadStats",
    value: function reloadStats() {
      this.getShortLinkStats({ url_id: this.props.params.short_link_uid });
      this.handleStats(this.state.created_on, this.state.current, this.state.stats.information);
    }
  }, {
    key: "renderLinks",
    value: function renderLinks() {
      var _this6 = this;

      var links = { short_url: '', long_url: '' };

      if (this.state.stats) {
        links.long_url = this.state.stats.long_url;
        links.short_url = this.state.stats.short_url;
        links.status = this.state.stats.status;
      }

      return _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.links },
        _react2.default.createElement(
          _reactCopyToClipboard2.default,
          { onCopy: function onCopy() {
              return _this6.handleCopyLink();
            }, text: links.short_url },
          _react2.default.createElement(
            "div",
            { className: _ShortLinkDetail2.default.link },
            _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.link_icon },
              _react2.default.createElement(md.clipboard, { size: 40 })
            ),
            _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.link_text },
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.link_title },
                "Link Corto"
              ),
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.url },
                links.short_url
              )
            )
          )
        ),
        _react2.default.createElement(
          _reactCopyToClipboard2.default,
          { onCopy: function onCopy() {
              return _this6.handleCopyLink();
            }, text: links.long_url },
          _react2.default.createElement(
            "div",
            { className: _ShortLinkDetail2.default.link },
            _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.link_icon },
              _react2.default.createElement(md.clipboard, { size: 40 })
            ),
            _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.link_text },
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.link_title },
                "Link Largo"
              ),
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.url },
                links.long_url
              )
            )
          )
        )
      );
    }
  }, {
    key: "renderStats",
    value: function renderStats() {

      var stats = { visits: '', last_visit: '', short_url: '', efectivity: null };

      if (this.state.stats) {
        stats.visits = this.state.stats.visits;
        stats.last_visit = this.state.stats.last_visit;
        stats.short_url = this.state.stats.short_url;
        stats.sms_sent = this.state.sms_link.sms_sent;
        if (stats.sms_sent > 0) {
          stats.efectivity = stats.visits / stats.sms_sent * 100;
        } else {
          stats.efectivity = 0;
        }
      }

      var visitStats = void 0;
      var smsStats = void 0;

      visitStats = _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.stats_data },
        _react2.default.createElement(
          "div",
          { className: _ShortLinkDetail2.default.left_stats },
          _react2.default.createElement(
            "span",
            null,
            "" + (0, _translations2.default)('VISITS')
          ),
          _react2.default.createElement(
            "h1",
            null,
            stats.visits ? (0, _numberFormat2.default)(stats.visits, 0) : 0
          )
        ),
        _react2.default.createElement(
          "div",
          { className: _ShortLinkDetail2.default.right_stats },
          _react2.default.createElement(
            "span",
            null,
            "" + (0, _translations2.default)('LAST VISIT')
          ),
          _react2.default.createElement(
            "h1",
            null,
            stats.last_visit ? (0, _moment2.default)(stats.last_visit).format('DD/MM/YY HH:mm') : "- -",
            " "
          )
        )
      );

      smsStats = _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.stats_data },
        _react2.default.createElement(
          "div",
          { className: _ShortLinkDetail2.default.left_stats },
          _react2.default.createElement(
            "span",
            null,
            "" + (0, _translations2.default)('Total de mensajes')
          ),
          _react2.default.createElement(
            "h1",
            null,
            stats.sms_sent ? (0, _numberFormat2.default)(stats.sms_sent, 0) : 0
          )
        ),
        _react2.default.createElement(
          "div",
          { className: _ShortLinkDetail2.default.right_stats },
          _react2.default.createElement(
            "span",
            null,
            "" + (0, _translations2.default)('Efectividad')
          ),
          _react2.default.createElement(
            "h1",
            null,
            stats.visits > 0 ? stats.efectivity.toFixed(2) + "%" : 0 + "%",
            " "
          )
        )
      );

      return _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.stats_container },
        visitStats,
        smsStats
      );
    }
  }, {
    key: "renderActions",
    value: function renderActions(data, hidden, visible) {
      var _this7 = this;

      var actionDelete = void 0;
      var actionReload = void 0;
      var actionCampaign = void 0;
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_ShortLinkList2.default);
      actionReload = _react2.default.createElement(
        "a",
        { onClick: function onClick() {
            return _this7.reloadStats();
          }, className: "" + _ShortLinkList2.default.action },
        _react2.default.createElement(
          "div",
          { className: _ShortLinkList2.default.icon },
          _react2.default.createElement(md.refresh, { size: 25 })
        ),
        _react2.default.createElement(
          "span",
          { className: _ShortLinkList2.default.sub },
          (0, _translations2.default)('RELOAD')
        )
      );

      if ((0, _sessionPermissions.sessionPermissions)('short:link:delete')) {

        actionDelete = _react2.default.createElement(
          "a",
          { onClick: function onClick() {
              return _this7.handleDeleteLink('INACTIVE');
            }, className: "" + _ShortLinkList2.default.action, style: data.status == 'INACTIVE' ? hidden : visible },
          _react2.default.createElement(
            "div",
            { className: _ShortLinkList2.default.icon },
            _react2.default.createElement(md.delete, { size: 25 })
          ),
          _react2.default.createElement(
            "span",
            { className: _ShortLinkList2.default.sub },
            (0, _translations2.default)('DEACTIVATE')
          )
        );
      }

      if ((0, _sessionPermissions.sessionPermissions)('short:link:delete')) {
        actionCampaign = _react2.default.createElement(
          "a",
          { onClick: function onClick() {
              return _this7.handleCampaign();
            }, className: "" + _ShortLinkList2.default.action_campaign, style: data.status == 'INACTIVE' ? hidden : visible },
          _react2.default.createElement("img", { className: _ShortLinkList2.default.icon, src: "/static/campaign.svg", alt: "" }),
          _react2.default.createElement(
            "span",
            { className: _ShortLinkList2.default.sub },
            (0, _translations2.default)('Nuevo envio con link')
          )
        );
      }
      return _react2.default.createElement(
        "div",
        { className: classesTheme },
        _react2.default.createElement(
          "div",
          { className: _ShortLinkList2.default.actions },
          actionCampaign,
          actionReload,
          actionDelete
        )
      );
    }
  }, {
    key: "renderList",
    value: function renderList() {

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.state.files.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.state.files.length > 0 ? hidden : visible;

      var header = void 0;
      var contentList = void 0;
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_ShortLinkList2.default);
      header = _react2.default.createElement(
        "div",
        { className: classesTheme },
        _react2.default.createElement(
          "div",
          { className: _ShortLinkList2.default.listheader },
          _react2.default.createElement(
            "div",
            { className: _ShortLinkList2.default.validList },
            (0, _translations2.default)('STATUS')
          ),
          _react2.default.createElement(
            "div",
            { className: _ShortLinkList2.default.nameList },
            (0, _translations2.default)('Nombre del archivo')
          ),
          _react2.default.createElement(
            "div",
            { className: _ShortLinkList2.default.dateList },
            (0, _translations2.default)('Fecha de carga')
          ),
          _react2.default.createElement(
            "div",
            { className: _ShortLinkList2.default.validList },
            (0, _translations2.default)('Cantidad de SMS')
          )
        )
      );

      contentList = _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.listContainer },
        _react2.default.createElement(
          "div",
          { style: infiniteStyle },
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.state.files.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemShortLinkFiles, {
                key: item._id,
                index: index,
                name: item.name,
                created_on: item.created_on,
                status: item.status,
                total_sms: (0, _numberFormat2.default)(item.stats.rows_ok, 0)
              });
            })
          )
        ),
        _react2.default.createElement(
          _Allow2.default,
          { permissions: ['short:link:send:file'] },
          _react2.default.createElement(_components.Linkholder, {
            style: linkstyle,
            to: {
              pathname: "/short_link/" + this.state.data._id + "/addFile"

            },
            src: '/static/empty/studies.svg',
            text: (0, _translations2.default)('NO FILES FOUND'),
            button: (0, _translations2.default)('SEND A NEW FILE LINK')
          })
        )
      );
      return _react2.default.createElement(
        "div",
        { className: _ShortLinkDetail2.default.file_list },
        header,
        contentList
      );
    }
  }, {
    key: "render",
    value: function render() {
      var hidden = { display: 'none' };
      var visible = { display: '' };
      var data = this.state.data;

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_ShortLinkList2.default);
      if (data) {
        return _react2.default.createElement(
          "div",
          { className: _ShortLinkDetail2.default.results },
          _react2.default.createElement(_reactHelmet2.default, { title: "Detalle del link" }),
          _react2.default.createElement(
            "div",
            { className: classesTheme },
            _react2.default.createElement(
              "div",
              { className: _ShortLinkList2.default.listheader },
              _react2.default.createElement(
                "div",
                { className: _ShortLinkList2.default.icon, onClick: this.props.router.goBack },
                _react2.default.createElement(md.arrowBack, { size: 20 })
              ),
              _react2.default.createElement(
                "div",
                null,
                _react2.default.createElement(
                  "div",
                  { className: _ShortLinkList2.default.nameTitle },
                  data.name ? data.name : 'Cargando'
                ),
                _react2.default.createElement(
                  "div",
                  { className: _ShortLinkList2.default.status },
                  this.statusTranslations[data.status]
                )
              )
            )
          ),
          _react2.default.createElement(
            "div",
            { className: _ShortLinkDetail2.default.main },
            this.renderActions(data, hidden, visible),
            this.renderLinks(),
            this.renderStats(),
            this.renderList(),
            _react2.default.createElement(
              "div",
              null,
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.chartContainer },
                _react2.default.createElement(
                  "div",
                  { className: _ShortLinkDetail2.default.pieChart, style: { display: "" + (this.state.os_for_graphic.length == 0 ? 'none' : 'block') } },
                  _react2.default.createElement(_components.GenericPieChart, { url_stats: { stats: this.state.os_for_graphic, name: 'Dispositivos' } })
                ),
                _react2.default.createElement(
                  "div",
                  { className: _ShortLinkDetail2.default.pieChart, style: { display: "" + (this.state.os_for_graphic.length == 0 ? 'none' : 'block') } },
                  _react2.default.createElement(_components.GenericPieChart, { url_stats: { stats: this.state.browser_for_graphic, name: 'Navegadores' } })
                )
              ),
              _react2.default.createElement(
                "div",
                { className: _ShortLinkDetail2.default.stockChart },
                _react2.default.createElement(_components.StockLine, { url_stats: { stats: this.state.values_for_graphic, tags: this.state.tags_for_graphic } })
              )
            ),
            _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.detail },
              "Creado el " + (0, _moment2.default)(data.created_on).format('LLLL')
            ),
            data.created_by ? _react2.default.createElement(
              "div",
              { className: _ShortLinkDetail2.default.detail },
              "Creado por " + data.created_by
            ) : null
          )
        );
      }
      return _react2.default.createElement("div", null);
    }
  }]);
  return ShortLinkDetail;
}(_react2.default.Component), _class3.propTypes = {
  shortlink: _propTypes2.default.array.isRequired,
  get: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, "datesList", [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, "datesList"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pathDeleteLink", [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, "pathDeleteLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleDeleteLink", [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, "handleDeleteLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleCopyLink", [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, "handleCopyLink"), _class2.prototype)), _class2)) || _class);
exports.default = ShortLinkDetail;
module.exports = exports["default"];