'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessagesFiles = exports.MessagesReceived = exports.MessagesSent = exports.default = exports.MessageTabs = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp, _dec, _class3, _dec2, _class4, _dec3, _class5, _class6, _temp2;

var _components = require('components');

var _files = require('redux/modules/files');

var _messages = require('redux/modules/messages');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _featureEnabled = require('helpers/featureEnabled');

var _themeEnabled = require('helpers/themeEnabled');

var _mobile = require('redux/modules/mobile');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Messages3 = require('./Messages.scss');

var _Messages4 = _interopRequireDefault(_Messages3);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  calendar: require('react-icons/lib/md/date-range'),
  message: require('react-icons/lib/md/email'),
  messageRead: require('react-icons/lib/md/drafts'),
  file: require('react-icons/lib/md/attachment')
};

var tabsToRender = [];

tabsToRender.push({
  href: '/messages/',
  title: (0, _translations2.default)('SENT'),
  is_index: true
});
tabsToRender.push({
  href: '/messages/received',
  title: (0, _translations2.default)('RECEIVED')
});

if ((0, _featureEnabled.featureEnabled)('campaigns')) {
  tabsToRender.push({
    href: '/campaigns/',
    title: (0, _translations2.default)('MY CAMPAIGNS')
  });
}

tabsToRender.push({
  href: '/messages/calendar',
  title: (0, _translations2.default)('CALENDAR VIEW')
});
tabsToRender.push({
  href: '/messages/files',
  title: (0, _translations2.default)('FILES')
});

var MessageTabs = exports.MessageTabs = function MessageTabs(props) {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    options: tabsToRender,
    onFilter: props.onFilter
  });
};

var Messages = (_class = (_temp = _class2 = function (_List) {
  (0, _inherits3.default)(Messages, _List);

  function Messages(props) {
    (0, _classCallCheck3.default)(this, Messages);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Messages.__proto__ || (0, _getPrototypeOf2.default)(Messages)).call(this, props));

    _this.title = (0, _translations2.default)('MESSAGES');
    _this.account_uid = props.auth.data.account_list.find(function (a) {
      return a.is_active;
    }).account_uid;

    _this.state = (0, _extends4.default)({}, _this.state, {
      filtering: false,
      startDate: null,
      endDate: null,
      errorLevel: ''
    });

    _this.url = 'messages';
    return _this;
  }

  (0, _createClass3.default)(Messages, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.props.dlrStatusCode().then(function (data) {
        _this2.setState((0, _extends4.default)({}, _this2.state, {
          dlrStatusCode: data
        }));
      });
      this.props.smppStatusCode().then(function (data) {
        _this2.setState((0, _extends4.default)({}, _this2.state, {
          smppStatusCode: data
        }));
      });
    }
  }, {
    key: 'getExtraParams',
    value: function getExtraParams() {
      return {
        account_uid: this.account_uid,
        messageType: this.messageType,
        start_date: this.state.startDate ? (0, _moment2.default)(this.state.startDate).format() : null,
        end_date: this.state.endDate ? (0, _moment2.default)(this.state.endDate).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format() : null,
        error_level: this.state.errorLevel || null
      };
    }
  }, {
    key: 'messagesUnselectAll',
    value: function messagesUnselectAll() {
      this.props.messagesUnselectAll(this.messageType);
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      if (this.isSelecting()) {
        return _react2.default.createElement(
          _components.ContextNav,
          {
            key: 'ContextNav',
            selectedElementsCount: this.props.selected.length,
            unselect: this.messagesUnselectAll
          },
          _react2.default.createElement(
            'button',
            { className: 'leftbutton' },
            'TEST'
          )
        );
      }
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY MESSAGES') },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          !this.state.search && _react2.default.createElement(md.search, { size: 16 }),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'handleFilter',
    value: function handleFilter() {
      var filtering = !this.state.filtering;
      this.setState((0, _extends4.default)({}, this.state, {
        filtering: filtering,
        startDate: null,
        endDate: null,
        errorLevel: ''
      }));
      return filtering;
    }
  }, {
    key: 'handleFilterChange',
    value: function handleFilterChange(which, element) {
      var value = '';
      if (typeof element === 'string') {
        value = element;
      } else {
        value = element.format('YYYY-MM-DD');
      }
      this.setState((0, _extends4.default)({}, this.state, (0, _defineProperty3.default)({}, which, value)));
    }
  }, {
    key: 'handleFilterSubmit',
    value: function handleFilterSubmit() {
      this.beginSearch();
    }
  }, {
    key: 'renderFilter',
    value: function renderFilter() {
      if (!this.state.filtering) {
        return null;
      }

      return _react2.default.createElement(
        'div',
        { className: _Messages4.default.filter },
        _react2.default.createElement(
          'div',
          { className: _Messages4.default.form },
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.input },
            _react2.default.createElement(
              'span',
              { className: _Messages4.default.calendar_title },
              (0, _translations2.default)('START DATE')
            ),
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.date },
              _react2.default.createElement(_components.Datepicker, { timeFormat: false, isAfter: false, compact: true, onChange: this.handleFilterChange.bind(this, 'startDate') })
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.input },
            _react2.default.createElement(
              'span',
              { className: _Messages4.default.calendar_title },
              (0, _translations2.default)('END DATE')
            ),
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.date },
              _react2.default.createElement(_components.Datepicker, { timeFormat: false, isAfter: false, compact: true, onChange: this.handleFilterChange.bind(this, 'endDate') })
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.buttons, style: { opacity: 0 } },
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.center_title },
              _react2.default.createElement(
                'div',
                (0, _defineProperty3.default)({ className: _Messages4.default.calendar_title }, 'className', _Messages4.default.select),
                _react2.default.createElement(
                  'span',
                  { className: _Messages4.default.calendar_title },
                  (0, _translations2.default)('CYCLE SELECTION')
                )
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.buttons },
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.center_title },
              _react2.default.createElement(
                'div',
                (0, _defineProperty3.default)({ className: _Messages4.default.calendar_title }, 'className', _Messages4.default.select),
                _react2.default.createElement(
                  'span',
                  { className: _Messages4.default.calendar_title },
                  (0, _translations2.default)('ADVANCED FILTERS')
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.selectdiv },
              _react2.default.createElement(
                'select',
                { value: this.state.errorLevel, onChange: this.handleFilterChange.bind(this, 'errorLevel') },
                _react2.default.createElement(
                  'option',
                  { value: '' },
                  (0, _translations2.default)('ALL MESSAGES')
                ),
                _react2.default.createElement(
                  'option',
                  { value: 'ERROR' },
                  (0, _translations2.default)('ERROR MESSAGES')
                ),
                _react2.default.createElement(
                  'option',
                  { value: 'OK' },
                  (0, _translations2.default)('OK MESSAGES')
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Messages4.default.filter_button },
          this.state.startDate && _react2.default.createElement(
            'div',
            { className: _Messages4.default.notice },
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.italic },
              (0, _translations2.default)('FILTERED'),
              ': '
            ),
            _react2.default.createElement(
              'div',
              { className: _Messages4.default.bold },
              (0, _translations2.default)('FROM'),
              ' ',
              this.state.startDate,
              ' ',
              (0, _translations2.default)('TO'),
              ' ',
              this.state.endDate
            )
          ),
          _react2.default.createElement(_components.Button, { color: 'green', name: (0, _translations2.default)('APPLY FILTER'), className: _Messages4.default.button, onClick: this.handleFilterSubmit })
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(MessageTabs, { onFilter: this.handleFilter });
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Messages4.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Messages4.default.listheader },
          _react2.default.createElement('div', { className: _Messages4.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.added },
            'Fecha'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.to },
            'Destino'
          ),
          this.messageType === _messages.messageTypes.SENT ? _react2.default.createElement(
            'div',
            { className: _Messages4.default.createdBy },
            'Creado por'
          ) : null,
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.message },
            'Mensaje'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this3 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      var destination = function destination(item) {
        return item.tag ? item.tag.split(',') : [item.msisdn || item.destination_identifier];
      };

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_reactHelmet2.default, { title: this.title }),
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            _components.Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemMessage, {
                key: item._id,
                index: index,
                direction: item.direction,
                date: item.datetime,
                message: item.content || '',
                created_by: item.created_by || '',
                status: item.event_status === 'ERROR' || item.delivery_status === 'ERROR' ? 'ERROR' : item.event_status,
                read_status: item.event_status === 'READ' ? 'READ' : 'UNREAD',
                delivery_status: item.delivery_status,
                destination: destination(item),
                mobileMode: _this3.props.mobileMode,
                channel: item.channel_type,
                count: item.event_count,
                onClick: function onClick(tag, event) {
                  event.preventDefault();
                  if (item.tag) {
                    _this3.props.push('/contacts?search=tags:' + item.tag);
                  } else if (item.msisdn) {
                    _this3.props.push({
                      pathname: '/contacts/' + item.msisdn,
                      state: {
                        modal: true,
                        returnTo: '/messages',
                        modalSize: { width: '850px', height: '500px' }
                      }
                    });
                  } else if (item.destination_identifier) {
                    _this3.props.push({
                      pathname: '/contacts/' + item.destination_identifier,
                      state: {
                        modal: true,
                        returnTo: '/messages',
                        modalSize: { width: '850px', height: '500px' }
                      }
                    });
                  }
                },
                link: {
                  pathname: '/' + _this3.url + '/detail',
                  state: {
                    modal: true,
                    returnTo: _this3.messageType === _messages.messageTypes.SENT ? '/' + _this3.url : '/' + _this3.url + '/received',
                    modalSize: { width: '830px', height: '400px' },
                    transaction_id: item._id,
                    status: item.event_status,
                    detail: {
                      title: (0, _translations2.default)('MESSAGE DETAIL'),
                      subtitle: (0, _translations2.default)('SENT ON') + ' ' + (0, _moment2.default)(item.datetime).format('DD/MM'),
                      content: item.content || "",
                      Icon: item.event_status == 'READ' ? md.messageRead : md.message,
                      count: item.event_count,
                      values: [[(0, _translations2.default)('SENT ON'), (0, _moment2.default)(item.datetime).format('YYYY-MM-DD HH:mm')], [(0, _translations2.default)('CREATED BY'), item.created_by], [(0, _translations2.default)('DESTINATION'), destination(item)], [(0, _translations2.default)('SENT MESSAGES'), item.total_credits - item.error_count], [(0, _translations2.default)('ERROR MESSAGES'), item.error_count], [(0, _translations2.default)('MESSAGE ERROR CODE'), item.error_code + ' - ' + (_this3.state.smppStatusCode.find(function (o) {
                        return o.sent_status_code === item.error_code;
                      }) ? _this3.state.smppStatusCode.find(function (o) {
                        return o.sent_status_code === item.error_code;
                      }).description : 'Sin descripción'), !item.error_code], [(0, _translations2.default)('DELIVERY STATUS'), (0, _translations2.default)('STATUS ' + item.delivery_status)], [(0, _translations2.default)('DELIVERY ERROR CODE'), item.delivery_error_code + ' - ' + (_this3.state.dlrStatusCode.find(function (o) {
                        return o.dlr_status_code_id === item.delivery_error_code;
                      }) ? _this3.state.dlrStatusCode.find(function (o) {
                        return o.dlr_status_code_id === item.delivery_error_code;
                      }).description : 'Sin descripción'), !item.delivery_error_code]
                      // [ _('ERROR DETAIL'), _('CLICK HERE TO VIEW ERROR DETAIL'),
                      //   !item.error_count, {
                      //     pathname: `/${this.url}/detail/${item._id}`,
                      //     state: {
                      //       modal: true,
                      //       returnTo: `/${this.url}/`,
                      //       modalSize: { width: '830px', height: '400px' },
                      //     }
                      //   }],
                      ]
                    }
                  }
                }
              });
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: '/' + this.url + '/add',
            state: { modal: true, returnTo: '/' + this.url + '/' }
          },
          src: (0, _useBasename2.default)('/static/empty/sms.svg'),
          text: (0, _translations2.default)('NO MESSAGES FOUND'),
          button: (0, _translations2.default)('SEND A NEW MESSAGE')
        })
      );
    }
  }]);
  return Messages;
}(_components.List), _class2.propTypes = {
  auth: _propTypes2.default.object.isRequired,
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  messagesUnselectAll: _propTypes2.default.func.isRequired,
  dlrStatusCode: _propTypes2.default.func.isRequired,
  smppStatusCode: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'messagesUnselectAll', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'messagesUnselectAll'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'handleFilter', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleFilter'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'handleFilterSubmit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleFilterSubmit'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'renderFilter', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'renderFilter'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'renderTabs', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'renderTabs'), _class.prototype)), _class);
exports.default = Messages;
var MessagesSent = exports.MessagesSent = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.messagesSent.selected,
    list: state.messagesSent.list,
    isLoading: state.messagesSent.loading,
    auth: state.auth
  };
}, {
  get: _messages.getTransactions,
  select: _messages.messageSelect,
  unselect: _messages.messageUnselect,
  messagesUnselectAll: _messages.messagesUnselectAll,
  refresh: _messages.sentMessagesRefresh,
  push: _reachHistory.push,
  dlrStatusCode: _messages.getDlrStatusCode,
  smppStatusCode: _messages.getSmppStatusCode
}), _dec(_class3 = function (_Messages) {
  (0, _inherits3.default)(MessagesSent, _Messages);

  function MessagesSent(props) {
    (0, _classCallCheck3.default)(this, MessagesSent);

    var _this4 = (0, _possibleConstructorReturn3.default)(this, (MessagesSent.__proto__ || (0, _getPrototypeOf2.default)(MessagesSent)).call(this, props));

    _this4.title = (0, _translations2.default)('MESSAGES_SENT');
    _this4.messageType = _messages.messageTypes.SENT;
    return _this4;
  }

  return MessagesSent;
}(Messages)) || _class3);
var MessagesReceived = exports.MessagesReceived = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.messagesReceived.selected,
    list: state.messagesReceived.list,
    isLoading: state.messagesReceived.loading,
    auth: state.auth
  };
}, {
  get: _messages.getTransactions,
  select: _messages.messageSelect,
  unselect: _messages.messageUnselect,
  messagesUnselectAll: _messages.messagesUnselectAll,
  refresh: _messages.receivedMessagesRefresh,
  push: _reachHistory.push,
  dlrStatusCode: _messages.getDlrStatusCode,
  smppStatusCode: _messages.getSmppStatusCode
}), _dec2(_class4 = function (_Messages2) {
  (0, _inherits3.default)(MessagesReceived, _Messages2);

  function MessagesReceived(props) {
    (0, _classCallCheck3.default)(this, MessagesReceived);

    var _this5 = (0, _possibleConstructorReturn3.default)(this, (MessagesReceived.__proto__ || (0, _getPrototypeOf2.default)(MessagesReceived)).call(this, props));

    _this5.title = (0, _translations2.default)('MESSAGES_RECEIVED');
    _this5.messageType = _messages.messageTypes.RECEIVED;
    return _this5;
  }

  return MessagesReceived;
}(Messages)) || _class4);
var MessagesFiles = exports.MessagesFiles = (_dec3 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.files.list,
    isLoading: state.files.loading
  };
}, {
  get: _files.getMessagesFiles,
  refresh: _files.filesRefresh,
  modal: _mobile.modal,
  push: _reachHistory.push
}), _dec3(_class5 = (_temp2 = _class6 = function (_List2) {
  (0, _inherits3.default)(MessagesFiles, _List2);

  function MessagesFiles() {
    (0, _classCallCheck3.default)(this, MessagesFiles);
    return (0, _possibleConstructorReturn3.default)(this, (MessagesFiles.__proto__ || (0, _getPrototypeOf2.default)(MessagesFiles)).apply(this, arguments));
  }

  (0, _createClass3.default)(MessagesFiles, [{
    key: 'renderNav',
    value: function renderNav() {
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY MESSAGES') },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          !this.state.search && _react2.default.createElement(md.search, { size: 16 }),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Messages4.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Messages4.default.listheader },
          _react2.default.createElement('div', { className: _Messages4.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.to },
            'Estado'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.added },
            'Fecha'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.message },
            'Tipo de Archivo'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.message },
            'Archivo'
          ),
          _react2.default.createElement(
            'div',
            { className: _Messages4.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(MessageTabs, null);
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      if (this.props.list && this.props.list.length === 0) {
        var visible = { display: 'block' };
        var linkstyle = visible;
        return _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(_components.Linkholder, {
            style: linkstyle,
            to: {
              pathname: '/messages/add',
              state: { modal: true, returnTo: '/messages/calendar' }
            },
            src: (0, _useBasename2.default)('/static/noCalendar.svg'),
            text: (0, _translations2.default)('NO FILES FOUND'),
            button: (0, _translations2.default)('SEND A NEW MESSAGE')
          })
        );
      }

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('MESSAGE FILES') }),
        _react2.default.createElement(
          _components.Infinite,
          {
            refresh: this.refreshRows,
            next: this.loadMoreRows,
            hasMore: this.state.hasMoreRows
          },
          this.props.list.map(function (item, index) {
            var upload = item.meta.path.substring(item.meta.path.lastIndexOf("/") + 1);
            var fileStatus = item.status == "SCHEDULED" ? "/scheduled" : '';
            return _react2.default.createElement(_components.VirtualListItemFile, {
              key: index,
              index: index,
              name: item.name,
              date: item.created_on,
              status: item.status,
              type: item.file_format,
              link: {
                pathname: '/messages/file' + fileStatus + '/detail',
                state: {
                  modal: true,
                  modalSize: { width: '830px', height: '600px' },
                  returnTo: '/messages/files/',
                  detail: {
                    file_uid: item._id,
                    created_on: item.created_on,
                    title: (0, _translations2.default)('MESSAGE FILES'),
                    subtitle: (0, _translations2.default)('LOADED ON') + ' ' + (0, _moment2.default)(item.created_on).format('DD/MM'),
                    content: item.name,
                    rows_ok: item.stats != undefined ? item.stats.rows_ok : 0,
                    monitoring_messages: item.stats != undefined ? item.stats.monitoring_messages || 0 : 0,
                    Icon: md.file,
                    monitoring: item.stats.monitoring,
                    values: [[(0, _translations2.default)('FILE CREATED ON'), (0, _moment2.default)(item.created_on).format('YYYY-MM-DD HH:mm')], [(0, _translations2.default)('CREATED BY'), item.created_by], [(0, _translations2.default)('ROWS FOUND'), item.total_lines], [(0, _translations2.default)('ROWS PROCESSED'), item.stats != undefined ? item.stats.messages_created : 0], [(0, _translations2.default)('ROWS OK'), item.stats != undefined ? item.stats.rows_ok : 0], [(0, _translations2.default)('Monitoreo'), item.stats != undefined ? item.stats.monitoring_messages || 0 : 0], [(0, _translations2.default)('ROWS DUPLICATED'), item.stats ? item.stats.rows_duplicated : 0], [(0, _translations2.default)('ROWS ERROR'), item.stats ? item.stats.rows_error : 0], [(0, _translations2.default)('FILE STATUS'), (0, _translations2.default)(item.status)], [(0, _translations2.default)('DOWNLOAD FILE'), item.meta ? item._id ? item.name : (0, _translations2.default)("FILE NOT FOUND") : (0, _translations2.default)("FILE NOT FOUND"), item.meta ? false : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? upload.concat(".csv") : (0, _translations2.default)("FILE NOT FOUND") : null)], [(0, _translations2.default)('PROCESS FILE'), (0, _translations2.default)('CLICK HERE TO DOWNLOAD ERROR FILE'), item ? item._id ? false : true : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? item._id.replace(".csv", "").concat("-cleaned.csv") : null : null)], [(0, _translations2.default)('ERROR FILE'), (0, _translations2.default)('CLICK HERE TO DOWNLOAD ERROR FILE'), item ? item._id ? false : true : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? item._id.replace(".csv", "").concat("-error.csv") : null : null)], ['Tipo de Archivo', item.file_format !== 'EMAIL' ? (0, _translations2.default)('TELEPHONE') : (0, _translations2.default)('EMAIL')]]
                  }
                }
              }
            });
          })
        )
      );
    }
  }, {
    key: 'linkTest',
    value: function linkTest() {
      return '';
    }
  }]);
  return MessagesFiles;
}(_components.List), _class6.propTypes = {
  mobileMode: _propTypes2.default.bool,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _class6.defaultProps = {
  selected: []
}, _temp2)) || _class5);