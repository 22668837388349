'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./CarrierIcon.scss');

var CarrierIcon = function (_Component) {
	(0, _inherits3.default)(CarrierIcon, _Component);

	function CarrierIcon(props) {
		(0, _classCallCheck3.default)(this, CarrierIcon);

		var _this = (0, _possibleConstructorReturn3.default)(this, (CarrierIcon.__proto__ || (0, _getPrototypeOf2.default)(CarrierIcon)).call(this, props));

		_this.manage_carrier_icon = function (carrier) {
			var carrierIconImg = null;
			switch (carrier) {
				default:
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '' });
					break;
				case 'Claro':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/claro.svg'), alt: '' });
					break;
				case 'Telefonica':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/claro.svg'), alt: '' });
					break;
				case 'Tigo':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/tigo.svg'), alt: '' });
					break;
				case 'FullMovil':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/fullmovil.svg'), alt: '' });
					break;
				case 'Movistar':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/movistar.svg'), alt: '' });
					break;
				case 'Kolbi':
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/kolbi.svg'), alt: '' });
					break;
				case null:
					carrierIconImg = _react2.default.createElement('img', { className: _this.props.detail ? styles.idk : styles.icon, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '' });
					break;
			}
			return carrierIconImg;
		};

		_this.renderIcons = function () {
			var _this$props$profile$p = _this.props.profile.profile,
			    first_name = _this$props$profile$p.first_name,
			    last_name = _this$props$profile$p.last_name;

			var _ref = _this.props.detail ? _this.props.profile : _this.props.profile.msisdn,
			    msisdn = _ref.msisdn;

			var icon = void 0;

			if (first_name && last_name) {
				var fullname = first_name.value + ' ' + last_name.value;
				icon = _react2.default.createElement(
					'div',
					{ className: styles.detail },
					_this.state.carrier_icon,
					_react2.default.createElement(
						'span',
						null,
						_this.props.forceMsisdn ? msisdn : fullname
					)
				);
			} else {
				icon = _react2.default.createElement(
					'div',
					{ className: styles.detail },
					_this.state.carrier_icon,
					_react2.default.createElement(
						'span',
						null,
						msisdn
					)
				);
			}
			return icon;
		};

		_this.state = {
			carrier_icon: null,
			availableIcons: [],
			icon: null
		};
		return _this;
	}

	(0, _createClass3.default)(CarrierIcon, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			var _props$profile$profil = this.props.profile.profile,
			    first_name = _props$profile$profil.first_name,
			    last_name = _props$profile$profil.last_name,
			    email = _props$profile$profil.email;
			var token = this.props.profile.token;

			var _ref2 = this.props.detail ? this.props.profile : this.props.profile.msisdn,
			    msisdn = _ref2.msisdn,
			    carrier = _ref2.carrier;

			var availableIcons = [];
			var icon = null;
			if (!this.props.detail) {
				if (msisdn) {
					icon = this.manage_carrier_icon(carrier);
					availableIcons.push(icon);
				}
				if (first_name || last_name) {
					icon = _react2.default.createElement('img', { className: styles.icon, src: (0, _useBasename2.default)('/static/contacto.svg'), alt: '' });
					availableIcons.push(icon);
				}
				if (email) {
					icon = _react2.default.createElement('img', { className: styles.icon, src: (0, _useBasename2.default)('/static/mail.svg'), alt: '' });
					availableIcons.push(icon);
				}
				if (token != null && token.length > 0) {
					icon = _react2.default.createElement('img', { className: styles.icon, src: (0, _useBasename2.default)('/static/token_v2.svg'), alt: '' });
					availableIcons.push(icon);
				}
				if (msisdn) {
					availableIcons.push(msisdn ? _react2.default.createElement(
						'span',
						null,
						msisdn
					) : null);
				}
			} else if (this.props.push && this.props.detail) {
				if (this.props.profile.profile.first_name || this.props.profile.profile.last_name) {
					var first_nameT = this.props.profile.profile.first_name ? this.props.profile.profile.first_name.value : '';
					var last_nameT = this.props.profile.profile.last_name ? this.props.profile.profile.last_name.value : '  ';
					icon = _react2.default.createElement(
						'div',
						{ className: styles.detail },
						_react2.default.createElement('img', { className: styles.icon, src: (0, _useBasename2.default)('/static/token_v2.svg'), alt: '' }),
						_react2.default.createElement(
							'span',
							null,
							first_nameT + ' ' + last_nameT
						)
					);
				} else {
					icon = _react2.default.createElement(
						'div',
						{ className: styles.detail },
						_react2.default.createElement('img', { className: styles.icon, src: (0, _useBasename2.default)('/static/token_v2.svg'), alt: '' }),
						_react2.default.createElement(
							'span',
							null,
							this.props.profile.push_tokens[0].substring(0, 8)
						)
					);
				}
				availableIcons.push(icon);
			} else {
				this.setState({ carrier_icon: this.manage_carrier_icon(carrier) });
			}
			this.setState({ availableIcons: availableIcons });
		}
	}, {
		key: 'render',
		value: function render() {
			var _state = this.state,
			    availableIcons = _state.availableIcons,
			    carrier_icon = _state.carrier_icon;

			return _react2.default.createElement(
				'div',
				{ className: styles.result },
				!carrier_icon ? availableIcons.map(function (icon) {
					return icon;
				}) : this.renderIcons()
			);
		}
	}]);
	return CarrierIcon;
}(_react.Component);

exports.default = CarrierIcon;
module.exports = exports['default'];