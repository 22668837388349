'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.fixString = fixString;
exports.getSmsPreview = getSmsPreview;
/* eslint-disable */
function fixString(text) {
    return text.replaceAll('á', 'a').replaceAll('é', 'e').replaceAll('í', 'i').replaceAll('ó', 'o').replaceAll('ú', 'u').replaceAll('Á', 'A').replaceAll('É', 'E').replaceAll('Í', 'I').replaceAll('Ó', 'O').replaceAll('Ú', 'U').replaceAll('ñ', 'ni').replaceAll('Ñ', 'Ni').replaceAll(/[^\000-\177]/g, '');
}

function getSmsPreview(shortName, message, messageLength, messageLengthMultiple) {
    var previewText = '';
    var cleanedText = fixString(shortName + message);
    var length = cleanedText.length;
    var re = new RegExp('[\\s\\S]{1,' + (length > messageLength ? messageLengthMultiple : messageLength) + '}', 'g');

    var parts = cleanedText.match(re) || [];
    parts.forEach(function (part, index) {
        if (index > 0) {
            previewText += '<hr />';
        }
        previewText += '<p style=\'word-wrap: break-word;\' readonly>' + part + '</p>';
    });
    return [previewText, length];
}