'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _dec, _connect, _class, _desc, _value, _class2;

var _shortLink = require('../../redux/modules/shortLink');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _Loading = require('../../components/Loading/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _themeEnabled = require('helpers/themeEnabled');

var _reachHistory = require('../../helpers/reachHistory');

var _NewShortLink = require('./NewShortLink.scss');

var _NewShortLink2 = _interopRequireDefault(_NewShortLink);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  delete: require('react-icons/lib/md/delete'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var NewShortLink = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    list: state.shortlink.list,
    user: state.auth.data.user,
    account: state.account.data.reach
  };
}, (_connect = {
  createShortLinks: _shortLink.createShortLinks,
  updateShortLinks: _shortLink.updateShortLinks
}, (0, _defineProperty3.default)(_connect, 'updateShortLinks', _shortLink.updateShortLinks), (0, _defineProperty3.default)(_connect, 'getShortLinks', _shortLink.getShortLinks), (0, _defineProperty3.default)(_connect, 'getAccount', _account.getAccount), (0, _defineProperty3.default)(_connect, 'push', _reachHistory.push), _connect)), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(NewShortLink, _React$Component);

  function NewShortLink(props) {
    (0, _classCallCheck3.default)(this, NewShortLink);

    var _this = (0, _possibleConstructorReturn3.default)(this, (NewShortLink.__proto__ || (0, _getPrototypeOf2.default)(NewShortLink)).call(this, props));

    _this.handleInformation = function () {
      if (_this.state.isEdit && _this.state.data.name === null && _this.props.list.length > 0) {
        var shortlink = _this.props.list.filter(function (item) {
          return item._id === _this.state.isEdit;
        });
        _this.setState({
          data: shortlink[0],
          save: 'Modificar',
          saveBegin: 'Modificar e iniciar'
        });
      }
    };

    _this.validationData = function (action) {
      var _this$state = _this.state,
          data = _this$state.data,
          validAlias = _this$state.validAlias;

      var forbidden = _this.validateUrl(data.url, "s.reach.tools");
      if (!data.url) {
        _this.setState({ validUrl: false });
      } else if (forbidden) {
        _this.setState({ forbiddenUrl: 'URL no válido' });
      } else if (!data.name) {
        _this.setState({ validName: false });
      } else if (!validAlias) {
        _this.setState({ validAlias: false });
      } else if (!_this.state.errorMessage && _this.state.isEdit === false) {
        _this.handleCreateShortLink(action);
      } else if (_this.state.isEdit) {
        _this.handleUpdateShortLink(action);
      }
    };

    _this.checkUrl = function () {
      var data = _this.state.data;

      var temp = data.url ? data.url.trim() : undefined;
      if (!temp) {
        _this.setState({ validUrl: false });
      }
    };

    _this.checkName = function () {
      var data = _this.state.data;

      var temp = data.name ? data.name.trim() : undefined;
      if (!temp) {
        _this.setState({ validName: false });
      }
    };

    _this.checkAlias = function () {
      var data = _this.state.data;

      var validAlias = false;
      validAlias = !/[^0-9a-zA-Z]/.test(data.alias) ? true : false;
      _this.setState({ data: data, validAlias: validAlias });
    };

    _this.handleCreateShortLink = function (action) {
      var data = _this.state.data;
      var base_url = _this.state.base_url;
      data.status = 'NEW';
      data.reference_type = 'SHORT_LINK';
      base_url ? data.base_url = base_url : null;
      _this.props.createShortLinks(data).then(function (result) {
        if (result) {
          _this.pathCreateShortLink();
        }
      }).catch(function (error) {
        _this.pathFailCreatingShortLink();
      });
    };

    _this.state = {
      colors: _this.colors,
      length: 0,
      data: {
        name: '',
        url: '',
        alias: '',
        url_status: 'ACTIVE',
        action: {
          text: null
        }
      },
      base_url: _this.props.account ? _this.props.account.short_link_base_url : null,
      forbiddenUrl: '',
      errorMessage: null,
      isEdit: _this.props.params.short_link_uid || false,
      save: '' + (0, _translations2.default)('JUST SAVE'),
      validUrl: true,
      validAlias: true,
      validName: true
    };

    if (_this.props.list.length === 0) _this.props.getShortLinks();
    return _this;
  }

  (0, _createClass3.default)(NewShortLink, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      if (!this.state.base_url) {
        this.props.getAccount().then(function (account) {
          _this2.setState({
            base_url: account.reach.short_link_base_url
          });
        });
      }

      this.handleInformation();
      this.setState({ loading: true });
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.handleInformation();
    }
  }, {
    key: 'validateUrl',
    value: function validateUrl(url, defaultUrl) {
      if (url.search(defaultUrl) == -1) {
        return false;
      } else {
        return true;
      }
    }
  }, {
    key: 'handleUrl',
    value: function handleUrl(event) {
      var data = this.state.data;
      var validUrl = void 0;
      data['url'] = event.target.value;
      var isforbiddenUrl = this.validateUrl(data['url'], 'reach.tools');
      validUrl = event.target.value !== null ? true : false;
      if (validUrl && isforbiddenUrl) {
        this.setState({ forbiddenUrl: 'URL no válido' });
      } else {
        this.setState({ forbiddenUrl: '' });
      }
      this.setState({ data: data, validUrl: validUrl });
    }
  }, {
    key: 'handleName',
    value: function handleName(event) {
      var data = this.state.data;
      var validName = void 0;
      data['name'] = event.target.value;
      validName = event.target.value !== null ? true : false;
      this.setState({ data: data, validName: validName });
    }
  }, {
    key: 'handleAlias',
    value: function handleAlias(event) {
      var data = this.state.data;
      var validAlias = void 0;
      data['alias'] = event.target.value;
      validAlias = !/[^0-9a-zA-Z]/.test(event.target.value) ? true : false;
      this.setState({ data: data, validAlias: validAlias });
    }
  }, {
    key: 'pathCreateShortLink',
    value: function pathCreateShortLink() {
      this.props.push({
        pathname: '/success',
        state: {
          goTo: '/short_link/',
          message: (0, _translations2.default)('CREATE LINK SUCCESS'),
          modal: true,
          returnTo: '/short_link/'
        }
      });
    }
  }, {
    key: 'pathFailCreatingShortLink',
    value: function pathFailCreatingShortLink() {
      this.props.push({
        pathname: '/fail',
        state: {
          goTo: '/short_link/',
          goToModal: false,
          message: (0, _translations2.default)('Error el link corto ya existe.'),
          modal: true,
          returnTo: '/short_link/'
        }
      });
    }
  }, {
    key: 'pahtUpdateLink',
    value: function pahtUpdateLink() {
      this.props.push({
        pathname: '/success',
        state: {
          goTo: '/short_link/',
          message: (0, _translations2.default)('UPDATE LINK SUCCESS'),
          modal: true,
          returnTo: '/short_link/'
        }
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      if (!this.state.loading) return _react2.default.createElement(_Loading2.default, null);

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_NewShortLink2.default);
      return _react2.default.createElement(
        'div',
        { className: _NewShortLink2.default.wrapper },
        _react2.default.createElement(_reactHelmet2.default, { title: this.state.isEdit ? (0, _translations2.default)('EDIT URL') : (0, _translations2.default)('NEW URL') }),
        _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: _NewShortLink2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _NewShortLink2.default.icon, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _NewShortLink2.default.main },
          _react2.default.createElement(
            'div',
            { className: _NewShortLink2.default.aside },
            _react2.default.createElement(
              'div',
              { className: _NewShortLink2.default.container },
              _react2.default.createElement(
                'div',
                { className: _NewShortLink2.default.text },
                _react2.default.createElement(
                  'label',
                  null,
                  ' ',
                  (0, _translations2.default)('NAME'),
                  ' '
                ),
                _react2.default.createElement('textarea', { onBlur: this.checkName, className: _NewShortLink2.default.messageComposer, value: this.state.data.name, onChange: function onChange(e) {
                    return _this3.handleName(e);
                  } }),
                _react2.default.createElement(
                  'span',
                  null,
                  this.state.validName ? '' : _react2.default.createElement(
                    'div',
                    { className: _NewShortLink2.default.errorStyle },
                    _react2.default.createElement(
                      'h3',
                      null,
                      (0, _translations2.default)('WRONG DATA')
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _NewShortLink2.default.text },
                _react2.default.createElement(
                  'label',
                  null,
                  ' ',
                  (0, _translations2.default)('LONG LINK'),
                  ' '
                ),
                _react2.default.createElement('textarea', { onBlur: this.checkUrl, className: _NewShortLink2.default.messageComposer, value: this.state.data.url, onChange: function onChange(e) {
                    return _this3.handleUrl(e);
                  }, placeholder: 'http://www.google.com' }),
                _react2.default.createElement(
                  'span',
                  null,
                  this.state.validUrl ? _react2.default.createElement(
                    'div',
                    { className: _NewShortLink2.default.errorStyle },
                    _react2.default.createElement(
                      'h3',
                      null,
                      this.state.forbiddenUrl
                    )
                  ) : _react2.default.createElement(
                    'div',
                    { className: _NewShortLink2.default.errorStyle },
                    _react2.default.createElement(
                      'h3',
                      null,
                      (0, _translations2.default)('WRONG DATA')
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _NewShortLink2.default.text },
                _react2.default.createElement(
                  'label',
                  null,
                  ' ',
                  (0, _translations2.default)('ALIAS'),
                  ' '
                ),
                _react2.default.createElement('textarea', { onBlur: this.checkAlias, className: _NewShortLink2.default.messageComposer, value: this.state.data.alias, onChange: function onChange(e) {
                    return _this3.handleAlias(e);
                  } }),
                _react2.default.createElement(
                  'span',
                  null,
                  this.state.validAlias ? '' : _react2.default.createElement(
                    'div',
                    { className: _NewShortLink2.default.errorStyle },
                    _react2.default.createElement(
                      'h3',
                      null,
                      (0, _translations2.default)('INVALID KEYWORD')
                    )
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          _Allow2.default,
          { permissions: ['short:link:save'] },
          _react2.default.createElement(
            'div',
            { className: _NewShortLink2.default.footer },
            _react2.default.createElement(
              'button',
              { onClick: function onClick() {
                  return _this3.validationData();
                }, className: _NewShortLink2.default.activateButton },
              ' ',
              this.state.save,
              ' '
            )
          )
        )
      );
    }
  }]);
  return NewShortLink;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'validateUrl', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'validateUrl'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleUrl', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleUrl'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleName', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleName'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleAlias', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleAlias'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'pathCreateShortLink', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'pathCreateShortLink'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'pathFailCreatingShortLink', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'pathFailCreatingShortLink'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'pahtUpdateLink', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'pahtUpdateLink'), _class2.prototype)), _class2)) || _class);
exports.default = NewShortLink;
module.exports = exports['default'];