'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reactRouter = require('react-router');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _components = require('components');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContactIndex = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    contact: state.contact,
    mobileMode: state.mobile.mobileMode
  };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(ContactIndex, _React$Component);

  function ContactIndex(props) {
    (0, _classCallCheck3.default)(this, ContactIndex);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactIndex.__proto__ || (0, _getPrototypeOf2.default)(ContactIndex)).call(this, props));

    _this.renderSpecial = function (variable) {
      var carrier = _this.props.contact.data;
      if (variable.key_text === 'msisdn') {
        return _react2.default.createElement(_components.CarrierIcon, { profile: carrier, detail: true, forceMsisdn: true });
      }
    };

    _this.styles = require('./Contact.scss');
    return _this;
  }

  (0, _createClass3.default)(ContactIndex, [{
    key: 'renderDateOfBirth',
    value: function renderDateOfBirth() {
      var profile = this.props.contact.data.profile;

      var day = profile.day_of_birth;
      var month = profile.month_of_birth;
      var year = profile.year_of_birth;
      return day && month && year ? _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          (0, _translations2.default)('BIRTH_DATE')
        ),
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          day.value + '/' + month.value + '/' + year.value
        )
      ) : _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          (0, _translations2.default)('BIRTH_DATE')
        ),
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          '---'
        )
      );
    }
  }, {
    key: 'renderVariable',
    value: function renderVariable(name, label) {
      var data = this.props.contact.data;
      var profile = this.props.contact.data.profile;
      var variable = profile[name];

      return variable ? _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          label
        ),
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          _react2.default.createElement(
            'div',
            { className: this.styles.variable },

            // this.render_carrier_logo(variable)
            variable.key_text === 'msisdn' ? this.renderSpecial(variable) : variable.value
          )
        )
      ) : _react2.default.createElement(
        'tr',
        null,
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          label
        ),
        _react2.default.createElement(
          'td',
          { className: this.styles.tablecell },
          '---'
        )
      );
    }
  }, {
    key: 'renderTags',
    value: function renderTags(tags) {
      var id = this.props.contact.data.id;

      var styles = this.styles;
      var maxTags = 20;
      var tagArray = tags.slice(0, maxTags).filter(function (e) {
        return e && typeof e === 'string';
      }).map(function (tag, index) {
        return _react2.default.createElement(
          'span',
          { className: styles.tag, key: index },
          tag
        );
      });
      if (tags.length > maxTags) {
        tagArray.push(_react2.default.createElement(
          _reactRouter.Link,
          (0, _defineProperty3.default)({ className: styles.more, key: maxTags + 1, to: '/contacts/' + id + '/tags' }, 'className', styles.discreteLink),
          tags.length - maxTags,
          ' ',
          (0, _translations2.default)('MORE TAGS'),
          '...'
        ));
      }
      return tagArray;
    }
  }, {
    key: 'renderProfile',
    value: function renderProfile() {
      var data = this.props.contact.data;

      var styles = this.styles;
      if (!this.props.contact || !this.props.contact.data || !this.props.contact.data.profile) {
        return null;
      }
      var variablesCount = (0, _getOwnPropertyNames2.default)(this.props.contact.data.profile).length - 5;
      // minus 5 to account for the currently rendered vars
      return _react2.default.createElement(
        'div',
        { className: styles.profile },
        _react2.default.createElement(
          'span',
          { className: styles.title },
          (0, _translations2.default)('PROFILE SUMMARY'),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['contacts:edit'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { className: styles.button, to: { pathname: '/contacts/' + data.id + '/vars', state: { modal: !this.props.mobileMode, returnTo: '/contacts/' } } },
              _react2.default.createElement('i', { className: 'fa fa-plus' }),
              ' ',
              (0, _translations2.default)('EDIT ALL VARIABLES')
            )
          )
        ),
        _react2.default.createElement(
          'table',
          { className: styles.table },
          _react2.default.createElement(
            'tbody',
            null,
            this.renderVariable('first_name', (0, _translations2.default)('FIRST_NAME')),
            this.renderVariable('last_name', (0, _translations2.default)('LAST_NAME')),
            this.renderVariable('gender', (0, _translations2.default)('GENDER')),
            this.renderVariable('msisdn', (0, _translations2.default)('PHONE_NUMBER')),
            this.renderVariable('email', (0, _translations2.default)('EMAIL')),
            this.renderVariable('push_tokens', 'Push Token'),
            this.renderDateOfBirth(),
            this.renderVariable('monitoring', 'Monitoreo'),
            variablesCount > 0 ? _react2.default.createElement(
              'tr',
              null,
              _react2.default.createElement(
                'td',
                { colSpan: 2 },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: { pathname: '/contacts/' + data.id + '/vars', state: { modal: !this.props.mobileMode, returnTo: '/contacts/' } }, className: styles.discreteLink },
                  variablesCount,
                  ' ',
                  (0, _translations2.default)('MORE VARIABLES'),
                  '...'
                )
              )
            ) : null
          )
        ),
        _react2.default.createElement(
          'span',
          { className: styles.title },
          (0, _translations2.default)('TAGS SUMMARY'),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['contacts:tags:edit'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { className: styles.button, to: { pathname: '/contacts/' + data.id + '/tags', state: { modal: !this.props.mobileMode, returnTo: '/contacts/' } } },
              _react2.default.createElement('i', { className: 'fa fa-plus' }),
              ' ',
              (0, _translations2.default)('VIEW AND EDIT ALL TAGS')
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tagset },
          this.renderTags(data.tags.text || data.tags.value)
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = this.styles;
      return _react2.default.createElement(
        'div',
        { className: styles.content },
        this.renderProfile()
      );
    }
  }]);
  return ContactIndex;
}(_react2.default.Component), _class2.propTypes = {
  params: _propTypes2.default.object,
  contact: _propTypes2.default.object,
  mobileMode: _propTypes2.default.bool.isRequired
}, _temp)) || _class);
exports.default = ContactIndex;
module.exports = exports['default'];