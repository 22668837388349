'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.v5Actions = exports.recoverActions = exports.logoutActions = exports.loginActions = exports.loadActions = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = reducer;
exports.isLoaded = isLoaded;
exports.load = load;
exports.changeAccount = changeAccount;
exports.login = login;
exports.logout = logout;
exports.recover = recover;
exports.changePassword = changePassword;
exports.verifyAccountUserToken = verifyAccountUserToken;
exports.changeAccountUserTokenPassword = changeAccountUserTokenPassword;
exports.verifyAccountEmailToken = verifyAccountEmailToken;
exports.v5Token = v5Token;
exports.twoFaToken = twoFaToken;
exports.twoFaTokenVerify = twoFaTokenVerify;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var loadActions = exports.loadActions = new _apiAction2.default('LOAD', [_apiAction.ACTION_TYPES.DATA]);
var loginActions = exports.loginActions = new _apiAction2.default('LOGIN', [_apiAction.ACTION_TYPES.DATA]);
var logoutActions = exports.logoutActions = new _apiAction2.default('LOGOUT', [_apiAction.ACTION_TYPES.DATA]);
var recoverActions = exports.recoverActions = new _apiAction2.default('RECOVER', [_apiAction.ACTION_TYPES.DATA]);
var v5Actions = exports.v5Actions = new _apiAction2.default('V5', [_apiAction.ACTION_TYPES.DATA]);

/*
export const actions = new ApiAction()
actionsToProperties(actions, {
  LOAD: 'AUTH_LOAD',
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  RECOVER: 'AUTH_RECOVER'
})
*/

// REDUCER


var initialState = {
  loading: true,
  status: loadActions.DATA
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var newState = null;
  newState = loadActions.getStateForAction(state, action);
  newState = loginActions.getStateForAction(newState, action);
  newState = logoutActions.getStateForAction(newState, action);
  newState = recoverActions.getStateForAction(newState, action);
  // TODO: move all the actions to a single one

  if (action.type === logoutActions.DATA_SUCCESS) {
    return (0, _extends3.default)({}, newState, {
      data: {}
    });
  }
  if (action.type === loadActions.DATA_SUCCESS || action.type === loginActions.DATA_SUCCESS || action.type === recoverActions.DATA_SUCCESS) {
    return (0, _extends3.default)({}, newState, {
      current_account: newState.data.account_list ? newState.data.account_list.find(function (a) {
        return a.is_active;
      }) : null
    });
  }
  return newState;
}

function isLoaded(globalState) {
  return globalState.auth && globalState.auth.status !== loadActions.DATA;
}

// ACTION CREATORS


function load() {
  return {
    types: [loadActions.DATA, loadActions.DATA_SUCCESS, loadActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/login/');
    }
  };
}

function changeAccount(accountUid) {
  return {
    types: [recoverActions.DATA, recoverActions.DATA_SUCCESS, recoverActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/changeaccount/', { params: { account_uid: accountUid } });
    }
  };
}

function login(username, password) {
  return {
    types: [loginActions.DATA, loginActions.DATA_SUCCESS, loginActions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/login/', {
        data: {
          username: username,
          password: password
        }
      });
    }
  };
}

function logout() {
  return {
    types: [logoutActions.DATA, logoutActions.DATA_SUCCESS, logoutActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/logout/');
    }
  };
}

function recover(email, partner) {
  return {
    types: [recoverActions.DATA, recoverActions.DATA_SUCCESS, recoverActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/user/recover_password_request', {
        params: {
          email: email,
          partner: partner
        }
      });
    }
  };
}

function changePassword() {
  return {
    types: [recoverActions.DATA, recoverActions.DATA_SUCCESS, recoverActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/user/change_password_request');
    }
  };
}

function verifyAccountUserToken(token) {
  var actions = recoverActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('verification/password/' + token, { data: {} });
    }
  };
}
function changeAccountUserTokenPassword(token, password, userId) {
  var actions = recoverActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('verification/password/' + token, { data: { password: password, user_id: userId } });
    }
  };
}

function verifyAccountEmailToken(token) {
  var actions = recoverActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('mail/verification/' + token, { data: {} });
    }
  };
}

function v5Token() {
  var actions = v5Actions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('user/create/token', { data: {} });
    }
  };
}

function twoFaToken(email) {
  var actions = recoverActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('two_factor/token', { data: { email: email } });
    }
  };
}

function twoFaTokenVerify(key, token) {
  var actions = recoverActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('two_factor/verify', { data: { key: key, token: token } });
    }
  };
}