'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.themeEnabled = themeEnabled;
exports.getThemeEnabledStyle = getThemeEnabledStyle;
exports.getAssetStyle = getAssetStyle;
exports.getAssetLoading = getAssetLoading;

var _clientStore = require('./clientStore.js');

var _clientStore2 = _interopRequireDefault(_clientStore);

var _useBasename = require('./useBasename.js');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HOST = {
    "ec.tigobusiness.hn": "tigo",
    "claro.gt.reach.tools": "claro",
    "claro.cr.reach.tools": "claro",
    "claro.reach.tools": "claro"
};

function themeEnabled() {
    if (!__CLIENT__) return "reach";

    var result = "reach";
    var hostname = "reach";
    var current_account_partner = "reach";
    var urlPath = window.location.href;
    if (HOST != null) {
        if (HOST[urlPath] != null) {
            hostname = HOST[urlPath];
        }
    }
    if (hostname.includes("claro")) {
        result = "claro";
    } else if (hostname.includes("tigo")) {
        result = "tigo";
    } else {
        var store = (0, _clientStore2.default)();
        if (store.getState().auth.current_account != null) {
            current_account_partner = store.getState().auth.current_account.partner;
            if (current_account_partner == "claro") {
                result = "claro";
            } else if (current_account_partner == "tigo") {
                result = "tigo";
            } else {
                result = "reach";
            }
        } else {
            result = "reach";
        }
    }

    if (result == null || result == undefined) {
        result = "reach";
    }

    return result;
}

function getThemeEnabledStyle(styles) {

    var result = null;

    try {
        result = themeEnabled();

        if (result == "claro") {
            result = styles.notiTheme;
        } else if (result == "tigo") {
            result = styles.tigoTheme;
        } else {
            result = styles.reachTheme;
        }
    } catch (e) {
        console.log('ERROR');
        console.log(e);
    }

    if (result == null || result == undefined || result == "reach") {
        result = styles.reachTheme;
    }

    return result;
}

function getAssetStyle() {
    var objAsset = {};
    if (themeEnabled() == "claro") {
        objAsset = {
            form: (0, _useBasename2.default)() + "/static/notificame/contact/asset_forms.svg",
            assetEnable: "/static/notificame/contact/asset_forms.svg",
            download: (0, _useBasename2.default)() + "/static/notificame/message/download.svg",
            apiRest: "/v4/help/docu_tecnica/docu_rest.html",
            apiHttp: "/v4/help/docu_tecnica/info_apihttp.html",
            iconCSV: (0, _useBasename2.default)() + "/static/notificame/message/csvExample.svg",
            iconContac: (0, _useBasename2.default)() + "/static/notificame/message/contactExample.svg",
            iconMessage: (0, _useBasename2.default)() + "/static/notificame/message/messageExample.svg",
            colorChart: "#0097a9"
        };
    } else {
        objAsset = {
            form: themeEnabled() == "tigo" ? (0, _useBasename2.default)() + "/static/asset_forms_tigo.svg" : (0, _useBasename2.default)() + "/static/asset_forms.svg",
            assetEnable: themeEnabled() == "tigo" ? "/static/asset_forms_tigo.svg" : "/static/asset_forms.svg",
            download: (0, _useBasename2.default)() + "/static/download.svg",
            apiRest: "https://docs.reach.tools/manual/documentacion-api-rest",
            apiHttp: "https://docs.reach.tools/manual/informacion-api-http",
            iconCSV: (0, _useBasename2.default)() + "/static/csvExample.svg",
            iconContac: (0, _useBasename2.default)() + "/static/contactExample.svg",
            iconMessage: (0, _useBasename2.default)() + "/static/messageExample.svg",
            colorChart: "#71abe3"
        };
    }
    return objAsset;
}

function getAssetLoading() {
    var assetEnable = "";
    if (themeEnabled() == "claro") {
        assetEnable = (0, _useBasename2.default)('/static/notificame/loading.gif');
    } else if (themeEnabled() == "tigo") {
        assetEnable = (0, _useBasename2.default)('/static/tigo/loading.gif');
    } else {
        assetEnable = (0, _useBasename2.default)('/static/reach_loading.gif');
    }
    return assetEnable;
}