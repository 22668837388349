'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VirtualListItemUser = exports.VirtualListItemPolicy = exports.VirtualListItemFile = exports.VirtualListItemMessage = exports.VirtualListItemCalendarOption = exports.VirtualListItemVariableOption = exports.VirtualListItemSchedule = exports.VirtualListItemSummaryDetail = exports.VirtualListItemSummary = exports.VirtualListItemVariable = exports.VirtualListItemInbox = exports.VirtualListItemTag = exports.VirtualListItemShortLinkFiles = exports.VirtualListItemShortLink = exports.VirtualListItemUserMessages = exports.VirtualListItemPromotion = exports.VirtualListItemQr = exports.VirtualListItemStudy = exports.VirtualListItemSlim = exports.VirtualListItemSubaccountUsers = exports.VirtualListItemAccount = exports.VirtualListItemContact = exports.default = exports.VirtualListItemLoad = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp, _class2, _temp2, _class3, _temp3;

var _messages = require('redux/modules/messages');

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _CarrierIcon = require('../CarrierIcon/CarrierIcon');

var _CarrierIcon2 = _interopRequireDefault(_CarrierIcon);

var _Html = require('../Preview/Html');

var _Html2 = _interopRequireDefault(_Html);

var _reactRouter = require('react-router');

var _Loading = require('../Loading/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactCssTransitionReplace = require('react-css-transition-replace');

var _reactCssTransitionReplace2 = _interopRequireDefault(_reactCssTransitionReplace);

var _SimpleTagList = require('../Tags/SimpleTagList');

var _SimpleTagList2 = _interopRequireDefault(_SimpleTagList);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

var _numberFormat = require('../../helpers/numberFormat');

var _numberFormat2 = _interopRequireDefault(_numberFormat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.locale('es');

var styles = require('./VirtualList.scss');

var md = {
  message: require('react-icons/lib/md/email'),
  messageRead: require('react-icons/lib/md/drafts'),
  html: require('react-icons/lib/md/cloud-queue'),
  language: require('react-icons/lib/md/language'),
  tag: require('react-icons/lib/md/label'),
  gesture: require('react-icons/lib/md/gesture'),
  assignment: require('react-icons/lib/md/assignment'),
  assignmentReturned: require('react-icons/lib/md/assignment-returned'),
  checkBox: require('react-icons/lib/md/check-box-outline-blank'),
  checkBoxChecked: require('react-icons/lib/md/check-box'),
  file: require('react-icons/lib/md/attachment'),
  fileError: require('react-icons/lib/md/error'),
  fileClock: require('react-icons/lib/md/access-time'),
  inbox: require('react-icons/lib/md/inbox'),
  cancel: require('react-icons/lib/md/cancel'),
  settings: require('react-icons/lib/md/settings'),
  gavel: require('react-icons/lib/md/gavel'),
  event: require('react-icons/lib/md/event'),
  checkCircle: require('react-icons/lib/md/check-circle'),
  stop: require('react-icons/lib/md/alarm-off'),
  report: require('react-icons/lib/md/assessment'),
  edit: require('react-icons/lib/md/border-color'),
  download: require('react-icons/lib/md/get-app'),
  promotion: require('react-icons/lib/md/record-voice-over'),
  detail: require('react-icons/lib/md/insert-drive-file'),
  link: require('react-icons/lib/md/link')
};

var VirtualItemCheck = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(VirtualItemCheck, _React$Component);

  function VirtualItemCheck() {
    (0, _classCallCheck3.default)(this, VirtualItemCheck);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualItemCheck.__proto__ || (0, _getPrototypeOf2.default)(VirtualItemCheck)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualItemCheck, [{
    key: 'renderInactive',
    value: function renderInactive(Icon) {
      return _react2.default.createElement('div', { key: 'inactiveicon', className: styles.check + ' ' + styles.checkInactive + ' ' + (this.props.selecting ? styles.selecting : '') });
    }
  }, {
    key: 'renderActive',
    value: function renderActive() {
      return _react2.default.createElement('div', { key: 'activeicon', className: styles.check + ' ' + styles.checkActive });
    }
  }, {
    key: 'render',
    value: function render() {
      var Icon = this.props.Icon;


      return _react2.default.createElement(
        _reactCssTransitionReplace2.default,
        {
          transitionName: 'rotate',
          transitionEnterTimeout: 200, transitionLeaveTimeout: 10,
          onClick: this.props.setActive
        },
        this.props.active ? this.renderActive() : this.renderInactive(Icon)
      );
    }
  }]);
  return VirtualItemCheck;
}(_react2.default.Component), _class.propTypes = {
  Icon: _propTypes2.default.element.isRequired,
  setActive: _propTypes2.default.func.isRequired,
  active: _propTypes2.default.bool,
  selecting: _propTypes2.default.bool
}, _temp);
var VirtualItemRadio = (_temp2 = _class2 = function (_React$Component2) {
  (0, _inherits3.default)(VirtualItemRadio, _React$Component2);

  function VirtualItemRadio() {
    (0, _classCallCheck3.default)(this, VirtualItemRadio);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualItemRadio.__proto__ || (0, _getPrototypeOf2.default)(VirtualItemRadio)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualItemRadio, [{
    key: 'renderInactive',
    value: function renderInactive(Icon) {
      return _react2.default.createElement('div', { key: 'inactiveicon', className: styles.radio + ' ' + styles.checkInactive + ' ' + (this.props.selecting ? styles.selecting : '') });
    }
  }, {
    key: 'renderActive',
    value: function renderActive() {
      return _react2.default.createElement('div', { key: 'activeicon', className: styles.radio + ' ' + styles.checkActive });
    }
  }, {
    key: 'render',
    value: function render() {
      var Icon = this.props.Icon;


      return _react2.default.createElement(
        _reactCssTransitionReplace2.default,
        {
          transitionName: 'rotate',
          transitionEnterTimeout: 200, transitionLeaveTimeout: 10,
          onClick: this.props.setActive
        },
        this.props.active ? this.renderActive() : this.renderInactive(Icon)
      );
    }
  }]);
  return VirtualItemRadio;
}(_react2.default.Component), _class2.propTypes = {
  Icon: _propTypes2.default.element.isRequired,
  setActive: _propTypes2.default.func.isRequired,
  active: _propTypes2.default.bool,
  selecting: _propTypes2.default.bool
}, _temp2);
var VirtualListItemLoad = exports.VirtualListItemLoad = function VirtualListItemLoad() {
  return _react2.default.createElement(_Loading2.default, { className: styles.loading, key: 'virtual-list-item-load' });
};

var VirtualListItem = (_temp3 = _class3 = function (_React$Component3) {
  (0, _inherits3.default)(VirtualListItem, _React$Component3);

  function VirtualListItem() {
    (0, _classCallCheck3.default)(this, VirtualListItem);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItem.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItem)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItem, [{
    key: 'setActive',
    value: function setActive(uid, value, event) {
      event.preventDefault();
      event.stopPropagation();
      this.props.setActive(uid, value, event);
    }
  }]);
  return VirtualListItem;
}(_react2.default.Component), _class3.propTypes = {
  index: _propTypes2.default.any.isRequired,
  icon: _propTypes2.default.element,
  active: _propTypes2.default.bool,
  setActive: _propTypes2.default.func,
  link: _propTypes2.default.object
}, _temp3);
exports.default = VirtualListItem;

var VirtualListItemContact = exports.VirtualListItemContact = function (_VirtualListItem) {
  (0, _inherits3.default)(VirtualListItemContact, _VirtualListItem);

  function VirtualListItemContact() {
    (0, _classCallCheck3.default)(this, VirtualListItemContact);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemContact.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemContact)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemContact, [{
    key: 'renderTags',
    value: function renderTags() {
      if (this.props.tags.text) {
        return this.props.tags.text.map(function (text) {
          return _react2.default.createElement(
            'div',
            { className: styles.tagItems },
            text
          );
        });
      }
    }
  }, {
    key: 'returnToken',
    value: function returnToken() {
      if (this.props.token && this.props.token.length > 0) {
        return this.props.token[0].substring(0, 8);
      } else {
        return 'Sin Token';
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemContact + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      var activate = this.setActive.bind(this, this.props.index, !this.props.active);

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link, onClick: this.props.link && !this.props.selecting ? null : this.setActive.bind(this, this.props.index, !this.props.active) },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(VirtualItemCheck, {
            Icon: this.props.icon,
            active: this.props.active,
            setActive: activate,
            selecting: this.props.selecting
          })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.added },
          (0, _moment2.default)(this.props.createdOn).format('DD/MM HH:mm')
        ),
        _react2.default.createElement(_CarrierIcon2.default, { profile: this.props, detail: false }),
        _react2.default.createElement(
          'div',
          { className: styles.token },
          this.props.name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.token1 },
          ' ',
          this.returnToken(),
          ' '
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tags },
          ' ',
          this.renderTags(),
          ' '
        )
      );
    }
  }]);
  return VirtualListItemContact;
}(VirtualListItem);

var VirtualListItemAccount = exports.VirtualListItemAccount = function (_VirtualListItem2) {
  (0, _inherits3.default)(VirtualListItemAccount, _VirtualListItem2);

  function VirtualListItemAccount() {
    (0, _classCallCheck3.default)(this, VirtualListItemAccount);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemAccount.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemAccount)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemAccount, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemContact + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      var activate = this.setActive.bind(this, this.props.index, !this.props.active);

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link,
          onClick: this.props.link && !this.props.selecting ? null : this.setActive.bind(this, this.props.index, !this.props.active),
          style: this.props.style },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(VirtualItemRadio, {
            active: this.props.active,
            setActive: activate,
            selecting: this.props.selecting,
            Icon: _react2.default.createElement(md.inbox, { size: 30 })
          })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.nameSub },
          this.props.account_name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.msisdnSub },
          this.props.sms_limit
        ),
        _react2.default.createElement(
          _Allow2.default,
          { permissions: ['reseller:prepaid:subaccount:create'] },
          _react2.default.createElement(
            'div',
            { className: styles.chargeSub },
            this.props.charge_type == 'PREPAID' ? (0, _translations2.default)('PREPAID ACCOUNT') : (0, _translations2.default)('POSTPAID ACCOUNT')
          )
        )
      );
    }
  }]);
  return VirtualListItemAccount;
}(VirtualListItem);

var VirtualListItemSubaccountUsers = exports.VirtualListItemSubaccountUsers = function (_VirtualListItem3) {
  (0, _inherits3.default)(VirtualListItemSubaccountUsers, _VirtualListItem3);

  function VirtualListItemSubaccountUsers() {
    (0, _classCallCheck3.default)(this, VirtualListItemSubaccountUsers);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemSubaccountUsers.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemSubaccountUsers)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemSubaccountUsers, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemContact + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      var activate = this.setActive.bind(this, this.props.index, !this.props.active);
      var selectedItem = this.props.selected;
      var active = selectedItem.indexOf(this.props.index) === -1 ? false : true;

      return _react2.default.createElement(
        'div',
        { className: classNames, style: this.props.style },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(VirtualItemRadio, {
            active: active,
            setActive: activate,
            selecting: this.props.selecting,
            Icon: _react2.default.createElement(md.inbox, { size: 30 })
          })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.userName
        ),
        _react2.default.createElement(
          'div',
          { className: styles.emailMini },
          this.props.userEmail
        ),
        _react2.default.createElement(
          'div',
          { className: styles.subAccounts },
          this.props.userAccount
        )
      );
    }
  }]);
  return VirtualListItemSubaccountUsers;
}(VirtualListItem);

var VirtualListItemSlim = exports.VirtualListItemSlim = function (_VirtualListItem4) {
  (0, _inherits3.default)(VirtualListItemSlim, _VirtualListItem4);

  function VirtualListItemSlim() {
    (0, _classCallCheck3.default)(this, VirtualListItemSlim);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemSlim.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemSlim)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemSlim, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemContact + ' ' + styles.slim + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link, onClick: this.props.link ? null : this.setActive.bind(this, this.props.index, !this.props.active) },
        _react2.default.createElement('div', null),
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          this.props.icon
        ),
        _react2.default.createElement('div', null),
        _react2.default.createElement(
          'div',
          { className: styles.msisdn },
          this.props.text
        ),
        this.props.count && _react2.default.createElement(
          'div',
          { className: styles.count },
          this.props.count
        )
      );
    }
  }]);
  return VirtualListItemSlim;
}(VirtualListItem);

var VirtualListItemStudy = exports.VirtualListItemStudy = function (_VirtualListItem5) {
  (0, _inherits3.default)(VirtualListItemStudy, _VirtualListItem5);

  function VirtualListItemStudy() {
    (0, _classCallCheck3.default)(this, VirtualListItemStudy);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemStudy.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemStudy)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemStudy, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + ' ' + styles.virtualItemStudy;
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          this.props.panelSource === 'DATA_STORE' ? _react2.default.createElement(md.assignment, { size: 30 }) : _react2.default.createElement(md.assignmentReturned, { size: 30 })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.label + ' ' + styles[this.props.labelClass] },
          this.props.label
        )
      );
    }
  }]);
  return VirtualListItemStudy;
}(VirtualListItem);

var VirtualListItemQr = exports.VirtualListItemQr = function (_VirtualListItem6) {
  (0, _inherits3.default)(VirtualListItemQr, _VirtualListItem6);

  function VirtualListItemQr() {
    (0, _classCallCheck3.default)(this, VirtualListItemQr);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemQr.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemQr)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemQr, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + ' ' + styles.virtualItemStudy;
      var selectedItem = this.props.selected;
      var active = selectedItem.indexOf(this.props.index) === -1 ? false : true;
      return _react2.default.createElement(
        'div',
        { className: classNames, onClick: this.props.onClick },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(VirtualItemRadio, {
            active: active,
            setActive: active,
            selecting: this.props.selecting,
            Icon: _react2.default.createElement(md.inbox, { size: 30 })
          })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.name ? this.props.name : (0, _translations2.default)('NO NAME QR')
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.url
        ),
        _react2.default.createElement(
          'div',
          { className: styles.label + ' ' + styles[this.props.status] },
          this.props.label
        )
      );
    }
  }]);
  return VirtualListItemQr;
}(VirtualListItem);

var VirtualListItemPromotion = exports.VirtualListItemPromotion = function (_VirtualListItem7) {
  (0, _inherits3.default)(VirtualListItemPromotion, _VirtualListItem7);

  function VirtualListItemPromotion() {
    (0, _classCallCheck3.default)(this, VirtualListItemPromotion);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemPromotion.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemPromotion)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemPromotion, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + ' ' + styles.virtualItemPromotion;
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(md.promotion, { size: 30 })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.startDate,
          ' \xA0 - \xA0 ',
          this.props.endDate,
          ' '
        ),
        _react2.default.createElement(
          'div',
          { className: styles.label + ' ' + styles[this.props.labelClass] },
          this.props.label
        )
      );
    }
  }]);
  return VirtualListItemPromotion;
}(VirtualListItem);

var VirtualListItemUserMessages = exports.VirtualListItemUserMessages = function (_VirtualListItem8) {
  (0, _inherits3.default)(VirtualListItemUserMessages, _VirtualListItem8);

  function VirtualListItemUserMessages() {
    (0, _classCallCheck3.default)(this, VirtualListItemUserMessages);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemUserMessages.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemUserMessages)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemUserMessages, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemUserMessages + ' ' + styles.virtualItemMessage;
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link },
        _react2.default.createElement('div', { className: styles.icon }),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.user
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.sms_sent
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.sms_error
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.total_sms
        )
      );
    }
  }]);
  return VirtualListItemUserMessages;
}(VirtualListItem);

var VirtualListItemShortLink = exports.VirtualListItemShortLink = function (_VirtualListItem9) {
  (0, _inherits3.default)(VirtualListItemShortLink, _VirtualListItem9);

  function VirtualListItemShortLink() {
    (0, _classCallCheck3.default)(this, VirtualListItemShortLink);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemShortLink.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemShortLink)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemShortLink, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemShortLink + ' ' + styles.virtualItemMessage;
      var active_link = (0, _useBasename2.default)('/static/link.svg');
      var inactive_link = (0, _useBasename2.default)('/static/link-stopped.svg');
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.icon },
          _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: this.props.status == 'ACTIVO' ? active_link : inactive_link, alt: '' })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.url_name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.shortlink },
          this.props.short_url
        ),
        _react2.default.createElement(
          'div',
          { className: styles.date },
          (0, _moment2.default)(this.props.created_on).format('LLL')
        ),
        _react2.default.createElement(
          'div',
          { className: styles.status },
          this.props.status
        ),
        _react2.default.createElement(
          'div',
          { className: styles.countAlignRight },
          _react2.default.createElement(
            'div',
            { className: styles.count },
            (0, _numberFormat2.default)(this.props.visits, 0)
          )
        )
      );
    }
  }]);
  return VirtualListItemShortLink;
}(VirtualListItem);

var VirtualListItemShortLinkFiles = exports.VirtualListItemShortLinkFiles = function (_VirtualListItem10) {
  (0, _inherits3.default)(VirtualListItemShortLinkFiles, _VirtualListItem10);

  function VirtualListItemShortLinkFiles() {
    (0, _classCallCheck3.default)(this, VirtualListItemShortLinkFiles);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemShortLinkFiles.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemShortLinkFiles)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemShortLinkFiles, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + ' ' + styles.virtualItemMessage;
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.tags },
          this.props.status === 'PROCESSING' && _react2.default.createElement(md.settings, { size: 30, color: '#67C4F9' }),
          this.props.status === 'ERROR' && _react2.default.createElement(md.cancel, { size: 30, color: '#FF7888' }),
          this.props.status === 'DONE' && _react2.default.createElement(md.checkCircle, { size: 30, color: '#1DB176' }),
          this.props.status === 'FINISHED' && _react2.default.createElement(md.file, { size: 30 }),
          this.props.status === 'READY' && _react2.default.createElement(md.file, { size: 30 }),
          this.props.status === 'BUILDING' && _react2.default.createElement(md.gavel, { size: 30, color: '#FFE36E' }),
          this.props.status === 'SCHEDULED' && _react2.default.createElement(md.event, { size: 30, color: '#98CA97' })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          (0, _moment2.default)(this.props.created_on).format('YYYY-MM-DD')
        ),
        _react2.default.createElement(
          'div',
          { className: styles.count },
          this.props.total_sms
        )
      );
    }
  }]);
  return VirtualListItemShortLinkFiles;
}(VirtualListItem);

var VirtualListItemTag = exports.VirtualListItemTag = function (_VirtualListItem11) {
  (0, _inherits3.default)(VirtualListItemTag, _VirtualListItem11);

  function VirtualListItemTag() {
    (0, _classCallCheck3.default)(this, VirtualListItemTag);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemTag.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemTag)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemTag, [{
    key: 'render',
    value: function render() {
      if (!this.props.tag) {
        return null;
      }
      var classNames = styles.virtualItemTag + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick ? this.props.onClick.bind(this, this.props.tag) : this.setActive.bind(this, this.props.index, !this.props.active) },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(md.tag, { size: 30 })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.tag
        ),
        this.props.count ? _react2.default.createElement(
          'div',
          { className: styles.count },
          this.props.count,
          ' ',
          (0, _translations2.default)('CONTACTS')
        ) : null
      );
    }
  }]);
  return VirtualListItemTag;
}(VirtualListItem);

var VirtualListItemInbox = exports.VirtualListItemInbox = function (_VirtualListItem12) {
  (0, _inherits3.default)(VirtualListItemInbox, _VirtualListItem12);

  function VirtualListItemInbox() {
    (0, _classCallCheck3.default)(this, VirtualListItemInbox);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemInbox.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemInbox)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemInbox, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + (this.props.active ? ' ' + styles.active : '') + (this.props.display ? '' : ' ' + styles.hidden) + (this.props.option ? ' ' + styles.option : '');

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          this.props.icon,
          this.props.num && _react2.default.createElement(
            'div',
            { className: styles.num },
            this.props.num
          )
        ),
        _react2.default.createElement(
          'div',
          { className: styles.msisdn },
          this.props.text
        ),
        this.props.text2 && _react2.default.createElement(
          'div',
          { className: styles.tag },
          this.props.text2
        )
      );
    }
  }]);
  return VirtualListItemInbox;
}(VirtualListItem);

var VirtualListItemVariable = exports.VirtualListItemVariable = function (_VirtualListItem13) {
  (0, _inherits3.default)(VirtualListItemVariable, _VirtualListItem13);

  function VirtualListItemVariable() {
    (0, _classCallCheck3.default)(this, VirtualListItemVariable);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemVariable.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemVariable)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemVariable, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemTag + (this.props.active ? ' ' + styles.active : '') + (this.props.display ? '' : ' ' + styles.hidden) + (this.props.option ? ' ' + styles.option : '');

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick, to: this.props.link },
        this.props.icon,
        _react2.default.createElement(
          'div',
          { className: styles.variable },
          this.props.text
        ),
        _react2.default.createElement(
          'div',
          { className: styles.variablecount },
          _react2.default.createElement(
            'span',
            null,
            this.props.count,
            ' clientes'
          )
        ),
        _react2.default.createElement(
          'div',
          null,
          this.props.handleicon
        )
      );
    }
  }]);
  return VirtualListItemVariable;
}(VirtualListItem);

var VirtualListItemSummary = exports.VirtualListItemSummary = function (_VirtualListItem14) {
  (0, _inherits3.default)(VirtualListItemSummary, _VirtualListItem14);

  function VirtualListItemSummary() {
    (0, _classCallCheck3.default)(this, VirtualListItemSummary);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemSummary.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemSummary)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemSummary, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: styles.virtualListItemSummary, onClick: this.props.onClick },
        _react2.default.createElement(
          'div',
          { className: styles.container },
          _react2.default.createElement(
            'div',
            { className: styles.main },
            _react2.default.createElement(
              'div',
              { className: styles.title },
              ' ',
              (0, _moment2.default)(this.props.date).format('dddd, D MMMM YYYY')
            ),
            _react2.default.createElement(
              'div',
              { className: styles.secondary },
              this.props.hour,
              ' | ',
              this.props.text
            )
          ),
          this.props.icon
        )
      );
    }
  }]);
  return VirtualListItemSummary;
}(VirtualListItem);

var VirtualListItemSummaryDetail = exports.VirtualListItemSummaryDetail = function (_VirtualListItem15) {
  (0, _inherits3.default)(VirtualListItemSummaryDetail, _VirtualListItem15);

  function VirtualListItemSummaryDetail() {
    var _ref;

    var _temp4, _this18, _ret;

    (0, _classCallCheck3.default)(this, VirtualListItemSummaryDetail);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp4 = (_this18 = (0, _possibleConstructorReturn3.default)(this, (_ref = VirtualListItemSummaryDetail.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemSummaryDetail)).call.apply(_ref, [this].concat(args))), _this18), _this18.getDays = function (value) {
      var days = [{ label: (0, _translations2.default)('MONDAY'), value: 1 }, { label: (0, _translations2.default)('TUESDAY'), value: 2 }, { label: (0, _translations2.default)('WEDNESDAY'), value: 3 }, { label: (0, _translations2.default)('THURSDAY'), value: 4 }, { label: (0, _translations2.default)('FRIDAY'), value: 5 }, { label: (0, _translations2.default)('SATURDAY'), value: 6 }, { label: (0, _translations2.default)('SUNDAY'), value: 0 }];
      if (value != undefined) {
        var lista = days.filter(function (day) {
          var filas = value.filter(function (value) {
            return day.value === value;
          });
          return filas.length > 0;
        });
        return lista.map(function (day, index) {
          return index > 0 ? "\t|\t" + day.label : day.label;
        });
      }
      return null;
    }, _temp4), (0, _possibleConstructorReturn3.default)(_this18, _ret);
  }

  (0, _createClass3.default)(VirtualListItemSummaryDetail, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: styles.virtualListItemSummaryDetail },
        _react2.default.createElement(
          'div',
          { className: styles.main },
          _react2.default.createElement(
            'div',
            { className: styles.secondary },
            ' ',
            (0, _translations2.default)('RECURRENCE'),
            ':  \xA0',
            _react2.default.createElement(
              'span',
              { className: styles.orangeText },
              this.props.recurrence.label
            )
          ),
          this.props.recurrence.term ? this.props.recurrence.term === 'WEEKLY' ? _react2.default.createElement(
            'div',
            { className: styles.secondary },
            ' \xA0 ',
            _react2.default.createElement(
              'span',
              { className: styles.orangeText },
              this.getDays(this.props.recurrence.value)
            )
          ) : null : null,
          this.props.recurrence.term === 'NO RECURRENT' ? null : _react2.default.createElement(
            'div',
            { className: styles.secondary },
            ' ',
            (0, _translations2.default)('ENDS'),
            ': \xA0',
            _react2.default.createElement(
              'span',
              { className: styles.orangeText },
              this.props.endDate
            ),
            ' '
          )
        )
      );
    }
  }]);
  return VirtualListItemSummaryDetail;
}(VirtualListItem);

var VirtualListItemSchedule = exports.VirtualListItemSchedule = function (_VirtualListItem16) {
  (0, _inherits3.default)(VirtualListItemSchedule, _VirtualListItem16);

  function VirtualListItemSchedule() {
    (0, _classCallCheck3.default)(this, VirtualListItemSchedule);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemSchedule.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemSchedule)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemSchedule, [{
    key: 'render',
    value: function render() {
      var classNames = styles.VirtualListItemSchedule + (this.props.active ? ' ' + styles.active : '') + (this.props.display ? '' : ' ' + styles.hidden) + (this.props.option ? ' ' + styles.option : '');

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick, to: this.props.link },
        this.props.icon,
        _react2.default.createElement(
          'div',
          { className: styles.variable },
          (0, _moment2.default)(this.props.hour).format('h:mm a'),
          ' | ',
          this.props.text
        ),
        _react2.default.createElement(
          'div',
          null,
          this.props.handleicon
        )
      );
    }
  }]);
  return VirtualListItemSchedule;
}(VirtualListItem);

var VirtualListItemVariableOption = exports.VirtualListItemVariableOption = function (_VirtualListItem17) {
  (0, _inherits3.default)(VirtualListItemVariableOption, _VirtualListItem17);

  function VirtualListItemVariableOption() {
    (0, _classCallCheck3.default)(this, VirtualListItemVariableOption);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemVariableOption.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemVariableOption)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemVariableOption, [{
    key: 'render',
    value: function render() {
      var classNames = (this.props.display ? '' : styles.hidden) + ' ' + styles.virtualItemVariableOption;

      var color = this.props.color;

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick, to: this.props.link, style: { marginLeft: this.props.marginLeft } },
        _react2.default.createElement(
          'span',
          { className: styles.count, title: this.props.count, style: { backgroundColor: color } },
          Math.round(this.props.percent * 100),
          '%'
        ),
        _react2.default.createElement(
          'div',
          { className: styles.variable },
          this.props.text
        ),
        _react2.default.createElement(
          'div',
          { className: styles.gauge },
          _react2.default.createElement('div', { className: styles.background, style: { backgroundColor: color } }),
          _react2.default.createElement('div', { className: styles.foreground, style: { width: (this.props.percent > 0.97 ? 0.97 : this.props.percent) * 100 + '%', backgroundColor: color } })
        )
      );
    }
  }]);
  return VirtualListItemVariableOption;
}(VirtualListItem);

var VirtualListItemCalendarOption = exports.VirtualListItemCalendarOption = function (_VirtualListItem18) {
  (0, _inherits3.default)(VirtualListItemCalendarOption, _VirtualListItem18);

  function VirtualListItemCalendarOption() {
    (0, _classCallCheck3.default)(this, VirtualListItemCalendarOption);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemCalendarOption.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemCalendarOption)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemCalendarOption, [{
    key: 'render',
    value: function render() {
      var classNames = (this.props.display ? '' : styles.hidden) + ' ' + styles.virtualItemCalendarOption;
      var color = this.props.color;
      var id = this.props.taskIds;
      var downloadLink = (0, _useBasename2.default)() + '/downloads/message-files/' + this.props.fileId + '-cleaned.csv';
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, onClick: this.props.onClick, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.column },
          _react2.default.createElement(
            'div',
            { className: styles.leftSide },
            _react2.default.createElement(
              'div',
              { className: styles.row },
              (0, _translations2.default)('MESSAGE TYPE'),
              ': ',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                '\xA0',
                this.props.frequency
              )
            ),
            this.props.type === 'FILE' ? _react2.default.createElement(
              'div',
              { className: styles.row },
              (0, _translations2.default)('FILE NAME'),
              ': \xA0 ',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                this.props.stats ? this.props.stats.name : null
              )
            ) : _react2.default.createElement('div', null),
            this.props.type === 'FILE' ? _react2.default.createElement(
              'div',
              { className: styles.row },
              (0, _translations2.default)('MESSAGE TOTAL'),
              ': \xA0 ',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                this.props.stats ? this.props.stats.stats.total_messages : 0
              )
            ) : _react2.default.createElement('div', null),
            this.props.type !== 'FILE' ? _react2.default.createElement(
              'div',
              { className: styles.row },
              this.props.type === 'SINGLE' && this.props.status === 'FINISHED' ? (0, _translations2.default)('CONTACTS SENT') : null,
              this.props.type === 'TAGS' && this.props.status === 'FINISHED' ? (0, _translations2.default)('TAGS SENT') : null,
              this.props.type === 'SINGLE' && this.props.status === 'SCHEDULED' ? (0, _translations2.default)('CONTACTS TO SEND') : null,
              this.props.type === 'TAGS' && this.props.status === 'SCHEDULED' ? (0, _translations2.default)('TAGS TO SEND') : null,
              ': \xA0 ',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                '\xA0',
                this.props.count
              )
            ) : _react2.default.createElement('div', null),
            this.props.endDate ? _react2.default.createElement(
              'div',
              { className: styles.row },
              ' ',
              (0, _translations2.default)('LAST DATE') + ': ',
              ' \xA0',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                (0, _moment2.default)(this.props.endDate).format('dddd, D MMMM YYYY')
              )
            ) : null,
            _react2.default.createElement(
              'div',
              { className: styles.row },
              (0, _translations2.default)('SENDING TYPE'),
              ': ',
              _react2.default.createElement(
                'span',
                { className: styles.greentext },
                '\xA0',
                this.props.type === 'SINGLE' ? (0, _translations2.default)('TO CONTACTS') : this.props.type === 'FILE' ? (0, _translations2.default)('TO FILE') : (0, _translations2.default)('TO TAGS')
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.actionButtonHolder },
              this.props.status === 'CREATED' || this.props.status === 'FINISHED' ? _react2.default.createElement(
                'div',
                { className: styles.actionButton },
                ' ',
                _react2.default.createElement(md.report, { size: 25 }),
                ' ',
                _react2.default.createElement(
                  'a',
                  { onClick: this.props.reportFileOrEventHandler },
                  _react2.default.createElement(
                    'div',
                    null,
                    (0, _translations2.default)('REPORT')
                  )
                )
              ) : _react2.default.createElement('div', null),
              this.props.type === 'FILE' ? _react2.default.createElement(
                'div',
                { className: styles.optionalButtons },
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['transactions:scheduled:view'] },
                  _react2.default.createElement(
                    'div',
                    { className: styles.actionButton },
                    ' ',
                    _react2.default.createElement(md.detail, { size: 25 }),
                    ' ',
                    _react2.default.createElement(
                      'a',
                      { onClick: this.props.getMessageFileDetailHandler },
                      _react2.default.createElement(
                        'div',
                        null,
                        (0, _translations2.default)('SHOW DETAIL')
                      )
                    )
                  )
                ),
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['transactions:file:scheduled:download'] },
                  _react2.default.createElement(
                    'div',
                    { className: styles.actionButton },
                    ' ',
                    _react2.default.createElement(md.download, { size: 25 }),
                    ' ',
                    _react2.default.createElement(
                      'a',
                      { href: downloadLink, target: '_blank' },
                      _react2.default.createElement(
                        'div',
                        null,
                        (0, _translations2.default)('DOWNLOAD FILE')
                      )
                    )
                  )
                )
              ) : _react2.default.createElement('div', null),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['transactions:scheduled:delete'] },
                this.props.status === 'SCHEDULED' ? _react2.default.createElement(
                  'div',
                  { className: styles.actionButton },
                  ' ',
                  _react2.default.createElement(md.stop, { size: 25 }),
                  ' ',
                  _react2.default.createElement(
                    _reactRouter.Link,
                    { to: { pathname: '/messages/confirmation', state: { modal: !this.props.mobileMode, returnTo: '/messages/calendar', deleteTaskHandler: this.props.deleteTaskHandler, taskId: this.props.taskId, data: this.props.name, date: this.props.executionDate } } },
                    _react2.default.createElement(
                      'div',
                      null,
                      ' ',
                      (0, _translations2.default)('STOP DELIVERY')
                    )
                  )
                ) : _react2.default.createElement('div', null)
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.rightSide },
            _react2.default.createElement(
              'div',
              { className: styles.message },
              _react2.default.createElement(
                'div',
                { className: styles.messageTitleHeader },
                (0, _translations2.default)('MESSAGE')
              ),
              _react2.default.createElement(
                'div',
                { className: styles.container },
                this.props.text ? this.props.text : this.props.stats ? this.props.stats.meta.message ? this.props.stats.meta.message : (0, _translations2.default)('MESSAGE ON FILE') : (0, _translations2.default)('MESSAGE ON FILE')
              )
            )
          )
        )
      );
    }
  }]);
  return VirtualListItemCalendarOption;
}(VirtualListItem);

var VirtualListItemMessageIcon = function VirtualListItemMessageIcon(props) {

  switch (props.channel) {
    case 'SMS':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '' });
    case 'RCS':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '' });
    case 'WHATSAPP':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/whatsapp.svg'), alt: '' });
    case 'PUSH':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/token_v2.svg'), alt: '' });
    case 'EMAIL':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/mail.svg'), alt: '' });
    case 'TEST':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '' });
    case 'HTML':
      return _react2.default.createElement(md.language, { size: 20 });
    case 'MESSENGER':
      return _react2.default.createElement('img', { style: { height: '20px', width: '20px' }, src: (0, _useBasename2.default)('/static/messenger.svg'), alt: '' });
    default:
      return _react2.default.createElement(md.message, { size: 20 });
  }
};

function VirtualListItemMessageDeliveredIcon(props) {
  if (props.channel !== 'SMS') {
    return '';
  }

  if (props.event_status === 'ERROR' || props.delivery_status === 'ERROR') {
    return _react2.default.createElement('img', { className: styles.deliveryStatus, src: (0, _useBasename2.default)('/static/error.svg'), alt: 'Error', title: 'Error' });
  }

  switch (props.event_status) {
    case 'READY':
      return _react2.default.createElement('img', { className: styles.deliveryStatus, src: (0, _useBasename2.default)('/static/waiting_icon.svg'), alt: 'Espera', title: 'Espera' });
    case 'SENT':
      switch (props.delivery_status) {
        case 'WAITING':
        case 'SUBMITTED':
          return _react2.default.createElement('img', { className: styles.deliveryStatus, src: (0, _useBasename2.default)('/static/sent_icon.svg'), alt: 'Enviado', title: 'Enviado' });
        case 'DELIVERED':
          return _react2.default.createElement('img', { className: styles.deliveryStatus, src: (0, _useBasename2.default)('/static/delivered.svg'), alt: 'Entregado', title: 'Entregado' });
        case 'VIEWED':
        case 'READ':
          return _react2.default.createElement('img', { className: styles.deliveryStatus, src: (0, _useBasename2.default)('/static/viewed_icon.svg'), alt: 'Visto', title: 'Visto' });
        default:
          return '';
      }
    default:
      return '';
  }
}

var VirtualListItemMessage = exports.VirtualListItemMessage = function (_VirtualListItem19) {
  (0, _inherits3.default)(VirtualListItemMessage, _VirtualListItem19);

  function VirtualListItemMessage() {
    (0, _classCallCheck3.default)(this, VirtualListItemMessage);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemMessage.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemMessage)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemMessage, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemMessage + (this.props.active ? ' ' + styles.active : '') + (this.props.status === 'ERROR' ? ' ' + styles.error : '') + (this.props.direction === 'IN' && this.props.read_status !== 'READ' ? ' ' + styles.virtualItemMessageBold : '');
      var dateFormat = 'YYYY-MM-DD HH:mm:ss';
      var date = (0, _moment2.default)(this.props.date).format('DD/MM HH:mm');
      var isHtml = this.props.message.trim().startsWith('<'); // TODO: make this less simplistic
      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link, title: isHtml ? null : this.props.message },
        _react2.default.createElement(
          'div',
          { className: this.props.direction === 'OUT' ? styles.handleDeliveredStatus : styles.handle },
          _react2.default.createElement(VirtualListItemMessageIcon, { count: this.props.count, channel: this.props.channel, status: this.props.status }),
          this.props.direction === 'OUT' ? _react2.default.createElement(VirtualListItemMessageDeliveredIcon, { channel: this.props.channel, delivery_status: this.props.delivery_status, event_status: this.props.status }) : null,
          this.props.direction === 'IN' ? _react2.default.createElement('div', { className: this.props.read_status === 'READ' ? styles.read : styles.unread }) : null
        ),
        !this.props.mobileMode ? _react2.default.createElement(
          'div',
          { className: styles.date },
          date
        ) : null,
        !this.props.mobileMode ? _react2.default.createElement(
          'div',
          { className: styles.tags },
          _react2.default.createElement(_SimpleTagList2.default, { longWidth: true, onClick: this.props.onClick,
            summary: this.props.count > 1 ? this.props.count + ' mensajes' : "1 mensaje", tags: this.props.destination, numTags: 1 })
        ) : null,
        !this.props.mobileMode && this.props.direction === 'OUT' ? _react2.default.createElement(
          'div',
          { className: styles.createdBy },
          this.props.created_by
        ) : null,
        isHtml ? _react2.default.createElement(_Html2.default, { html: this.props.message, className: styles.middle }) : _react2.default.createElement(
          'div',
          { className: styles.middle + ' ' + styles.message },
          this.props.message
        )
      );
    }
  }]);
  return VirtualListItemMessage;
}(VirtualListItem);

var VirtualListItemFile = exports.VirtualListItemFile = function (_VirtualListItem20) {
  (0, _inherits3.default)(VirtualListItemFile, _VirtualListItem20);

  function VirtualListItemFile() {
    (0, _classCallCheck3.default)(this, VirtualListItemFile);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemFile.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemFile)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemFile, [{
    key: 'render',
    value: function render() {
      var dateFormat = 'YYYY-MM-DD HH:mm:ss';
      var date = (0, _moment2.default)(this.props.date).format('DD/MM HH:mm');

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: styles.virtualItemFile, to: this.props.link },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          this.props.status === 'PROCESSING' && _react2.default.createElement(md.settings, { size: 30, color: '#67C4F9' }),
          this.props.status === 'ERROR' && _react2.default.createElement(md.cancel, { size: 30, color: '#FF7888' }),
          this.props.status === 'DONE' && _react2.default.createElement(md.checkCircle, { size: 30, color: '#1DB176' }),
          this.props.status === 'FINISHED' && _react2.default.createElement(md.file, { size: 30 }),
          this.props.status === 'READY' && _react2.default.createElement(md.file, { size: 30 }),
          this.props.status === 'BUILDING' && _react2.default.createElement(md.gavel, { size: 30, color: '#FFE36E' }),
          this.props.status === 'SCHEDULED' && _react2.default.createElement(md.event, { size: 30, color: '#98CA97' })
        ),
        !this.props.mobileMode ? _react2.default.createElement(
          'div',
          { className: styles.date },
          date
        ) : null,
        this.props.fileType ? _react2.default.createElement(
          'div',
          { className: styles.fileType },
          this.props.fileType
        ) : null,
        _react2.default.createElement(
          'div',
          { className: styles.type, style: { marginRight: this.props.type !== 'EMAIL' ? '196px' : null } },
          this.props.type == 'EMAIL' ? (0, _translations2.default)('EMAIL') : (0, _translations2.default)('SMS')
        ),
        _react2.default.createElement(
          'div',
          { className: styles.filename },
          this.props.name
        )
      );
    }
  }]);
  return VirtualListItemFile;
}(VirtualListItem);

var VirtualListItemPolicy = exports.VirtualListItemPolicy = function (_VirtualListItem21) {
  (0, _inherits3.default)(VirtualListItemPolicy, _VirtualListItem21);

  function VirtualListItemPolicy() {
    (0, _classCallCheck3.default)(this, VirtualListItemPolicy);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemPolicy.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemPolicy)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemPolicy, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualItemPolicy + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      var activate = this.setActive.bind(this, this.props.index, !this.props.active);

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link, onClick: this.props.link && !this.props.selecting ? null : this.setActive.bind(this, this.props.index, !this.props.active) },
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.policy_name
        ),
        _react2.default.createElement(
          'div',
          { className: styles.msisdn },
          this.props.policy_type
        )
      );
    }
  }]);
  return VirtualListItemPolicy;
}(VirtualListItem);

var VirtualListItemUser = exports.VirtualListItemUser = function (_VirtualListItem22) {
  (0, _inherits3.default)(VirtualListItemUser, _VirtualListItem22);

  function VirtualListItemUser() {
    (0, _classCallCheck3.default)(this, VirtualListItemUser);
    return (0, _possibleConstructorReturn3.default)(this, (VirtualListItemUser.__proto__ || (0, _getPrototypeOf2.default)(VirtualListItemUser)).apply(this, arguments));
  }

  (0, _createClass3.default)(VirtualListItemUser, [{
    key: 'render',
    value: function render() {
      var classNames = styles.virtualListItemUser + ' ' + (this.props.active ? ' ' + styles.active : '') + ' ' + (this.props.modal ? styles.modal : '');
      var activate = this.setActive.bind(this, this.props.index, !this.props.active);

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: classNames, to: this.props.link, onClick: this.props.link && !this.props.selecting ? null : this.setActive.bind(this, this.props.index, !this.props.active) },
        _react2.default.createElement(
          'div',
          { className: styles.handle },
          _react2.default.createElement(VirtualItemCheck, {
            Icon: this.props.icon,
            active: this.props.active,
            setActive: activate,
            selecting: this.props.selecting
          })
        ),
        _react2.default.createElement('div', { className: styles.spaceBetweenEmail }),
        _react2.default.createElement(
          'div',
          { className: styles.email },
          this.props.email,
          ' '
        ),
        _react2.default.createElement(
          'div',
          { className: styles.name },
          this.props.name,
          ' '
        ),
        _react2.default.createElement(
          'div',
          { className: styles.role },
          this.props.roles,
          ' '
        )
      );
    }
  }]);
  return VirtualListItemUser;
}(VirtualListItem);