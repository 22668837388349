'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('components');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _Detail = require('./Detail.scss');

var _Detail2 = _interopRequireDefault(_Detail);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _Snackbar = require('../Snackbar/Snackbar');

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back'),
  report: require('react-icons/lib/md/assessment')
};

var Detail = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(Detail, _React$Component);

  function Detail() {
    (0, _classCallCheck3.default)(this, Detail);
    return (0, _possibleConstructorReturn3.default)(this, (Detail.__proto__ || (0, _getPrototypeOf2.default)(Detail)).apply(this, arguments));
  }

  (0, _createClass3.default)(Detail, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var props = this.props.location.state.detail || this.props;
      var title = props.title,
          subtitle = props.subtitle,
          content = props.content,
          Icon = props.Icon,
          values = props.values,
          subdetailTitle = props.subdetailTitle;
      // values: [ label, value, hide(true/false), href(makes it a link) ]

      var isHtml = content ? content.trim().startsWith('<') : ""; // TODO: make this less simplistic

      var columns = [{
        Header: (0, _translations2.default)("DATE"),
        accessor: "key_as_string",
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            (0, _moment2.default)(props.value).format('DD/MM/YYYY')
          );
        }
      }, {
        Header: (0, _translations2.default)("SENT MESSAGES"),
        accessor: "messages_sent",
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            props.value.doc_count
          );
        }
      }, {
        Header: (0, _translations2.default)("ERRORS"),
        accessor: "errors",
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            props.value.doc_count + (_this2.props.rows_ok + _this2.props.monitoring_messages - props.original.messages.doc_count)
          );
        }
      }, {
        Header: (0, _translations2.default)("VIEWED"),
        accessor: "messages_viewed",
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            props.value.doc_count
          );
        }
      }];

      return _react2.default.createElement(
        'div',
        { className: _Detail2.default.detail },
        this.props.isFile && _react2.default.createElement(_Snackbar2.default, {
          messageText: this.props.snack.message,
          variant: this.props.snack.status,
          isOpen: this.props.snack.show,
          autoHideDuration: 3900,
          handleClose: this.props.hideSnackBar
        }),
        _react2.default.createElement(_reactHelmet2.default, { title: title }),
        _react2.default.createElement(
          'div',
          { className: _Detail2.default.bar },
          _react2.default.createElement(
            'div',
            { className: _Detail2.default.icon, onClick: this.props.router.goBack },
            _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
          ),
          _react2.default.createElement(
            'div',
            { className: _Detail2.default.title },
            _react2.default.createElement(
              'h1',
              null,
              '\xA0'
            ),
            _react2.default.createElement(
              'h2',
              null,
              subtitle
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Detail2.default.content },
          isHtml ? _react2.default.createElement(
            'div',
            { className: _Detail2.default.preview75 },
            _react2.default.createElement(_components.HtmlPreview, { html: content })
          ) : _react2.default.createElement(
            _react2.default.Fragment,
            null,
            _react2.default.createElement(
              'div',
              { className: _Detail2.default.successContainer },
              _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                  'h4',
                  null,
                  this.props.isFile ? (0, _translations2.default)('MESSAGE SEND SUCCESS') : "Nombre del archivo"
                )
              ),
              this.props.isFile && _react2.default.createElement(
                _Allow2.default,
                { permissions: ["account:reports:transactions:download"] },
                _react2.default.createElement(
                  'div',
                  { className: _Detail2.default.actionButton },
                  _react2.default.createElement(md.report, { size: 25 }),
                  ' ',
                  _react2.default.createElement(
                    'a',
                    { onClick: this.props.onRequestLog },
                    (0, _translations2.default)("REQUEST REPORT")
                  )
                )
              )
            ),
            content,
            this.props.isFile && _react2.default.createElement(
              'div',
              { className: _Detail2.default.tableContainer },
              _react2.default.createElement(_components.Table, {
                data: this.props.messagesScheduled.loading ? [] : this.props.messagesScheduled.data.result,
                columns: columns,
                isLoading: false,
                pageSize: 9
              })
            )
          )
        ),
        _react2.default.createElement(
          'table',
          { className: _Detail2.default.table },
          _react2.default.createElement(
            'tbody',
            null,
            values.filter(function (d) {
              return !d[2];
            }).map(function (d) {
              return _react2.default.createElement(
                'tr',
                { key: d[0].replace(/\s/g, '') },
                _react2.default.createElement(
                  'td',
                  null,
                  d[0]
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  typeof d[3] === 'undefined' ? d[1] : null,
                  typeof d[3] === 'string' && d[3] ? _react2.default.createElement(
                    'a',
                    { className: _Detail2.default.link, href: d[3] },
                    d[1]
                  ) : null,
                  (0, _typeof3.default)(d[3]) === 'object' && d[3] ? _react2.default.createElement(
                    _reactRouter.Link,
                    { className: _Detail2.default.link, to: d[3] },
                    d[1]
                  ) : null
                )
              );
            })
          )
        )
      );
    }
  }]);
  return Detail;
}(_react2.default.Component), _class.defaultProps = {
  location: {
    state: {}
  }
}, _temp);
exports.default = Detail;
module.exports = exports['default'];