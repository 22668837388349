'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _components = require('components');

var _auth = require('redux/modules/auth');

var _schedulerTasks = require('../../redux/modules/schedulerTasks');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _reactReduxLoadingBar2 = _interopRequireDefault(_reactReduxLoadingBar);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reduxConnect = require('redux-connect');

var _config = require('config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _partner = require('../../helpers/partner');

var _reachHistory = require('../../helpers/reachHistory');

var _mobile = require('redux/modules/mobile');

var _App = require('./App.scss');

var _App2 = _interopRequireDefault(_App);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// export default () => <h1>Hello 2</h1>


var App = (_dec = (0, _reactRedux.connect)(
/* state => props */
function (state) {
  return {
    auth: state.auth,
    mobile: state.mobile,
    loading: state.loadingBar.default
  };
}, {
  // dispatch =>
  logout: _auth.logout,
  pushState: _reachHistory.push
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(App, _React$Component);

  function App(props) {
    (0, _classCallCheck3.default)(this, App);

    var _this = (0, _possibleConstructorReturn3.default)(this, (App.__proto__ || (0, _getPrototypeOf2.default)(App)).call(this, props));

    _this.handleLogout = function (event) {
      event.preventDefault();
      _this.props.logout();
    };

    _this.themeWidthVariable = '--app-container-width';
    _this.themeOverflowYVariable = '--app-container-overflow-y';
    _this.themeHeightVariable = '--app-container-height';
    _this.themePaddingVariable = '--app-container-padding';

    _this.state = {
      title: ''
    };
    return _this;
  }

  (0, _createClass3.default)(App, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (!this.props.auth && nextProps.auth) {
        // login
        this.props.pushState('/loginSuccess');
      } else if (!nextProps.auth) {
        // logout
        this.props.pushState((0, _useBasename2.default)('/login/'));
      }

      if (nextProps.location.pathname === this.props.location.pathname) {
        if (!nextProps.location.state || !nextProps.location.state.modal) {
          this.previousChildren = this.props.children;
        }
      }
    }
  }, {
    key: 'willLeave',
    value: function willLeave() {
      return;
    }
  }, {
    key: 'isModal',
    value: function isModal() {
      var _props = this.props,
          mobileMode = _props.mobile.mobileMode,
          location = _props.location;


      return !!(location.state && location.state.modal && !this.props.mobile.mobileMode && this.previousChildren);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      // Set the value of the CSS variable
      if ((0, _partner.partnerByHost)() == 'tigo') {
        document.documentElement.style.setProperty(this.themeWidthVariable, '100%');
        document.documentElement.style.setProperty(this.themeHeightVariable, '200vh');
        document.documentElement.style.setProperty(this.themeOverflowYVariable, 'auto');
        document.documentElement.style.setProperty(this.themePaddingVariable, '20px 2vw');
      } else {
        document.documentElement.style.setProperty(this.themeWidthVariable, '1080px');
        document.documentElement.style.setProperty(this.themeHeightVariable, '100%');
        document.documentElement.style.setProperty(this.themeOverflowYVariable, 'hidden');
        document.documentElement.style.setProperty(this.themePaddingVariable, '0');
      }
      // Force a re-render to apply the new CSS variable value
      this.forceUpdate();
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          mobileMode = _props2.mobile.mobileMode,
          location = _props2.location;


      var isModal = this.isModal();
      var returnTo = location.state && location.state.returnTo ? location.state.returnTo : location.pathname;

      var defaultHeader = true;
      if (location.pathname === '/' || location.pathname === '/callback') {
        defaultHeader = false;
      }

      if (__CLIENT__) {
        if (isModal) {
          document.body.classList.add('modalBody');
        } else {
          document.body.classList.remove('modalBody');
        }
      }
      var ravenkey = 'f0dc00f6736748f0af2885c2935989fd';
      var ravenproject = '2';
      var ravenconfig = {
        release: '4.0.9', // read from package.json or app.json
        environment: __DEVELOPMENT__ ? 'develop' : 'production',
        shouldSendCallback: function shouldSendCallback() {
          console.warn('Sent error report to sentry.');
          return true;
        }
      };

      var partnerLogo;
      var partner = (0, _partner.getPartnerByHost)();
      console.log(partner, 'REACT_APP_HOST partner');
      if (this.props.auth.current_account && !partner) {
        partner = this.props.auth.current_account.partner;
      }
      // favicon.href = "https://www.google.com/favicon.ico";
      if (partner) partnerLogo = '/static/partner-' + partner + '.png';
      //LogRocket.init('ptkbop/toolkit')
      var iconLoading = (0, _useBasename2.default)('/static/reach_loading.gif');
      if (partner == "claro") {
        partnerLogo = null;
        iconLoading = (0, _useBasename2.default)('/static/notificame/loading.gif');
      }
      if (partner == "tigo") {
        partnerLogo = null;
        iconLoading = (0, _useBasename2.default)('/static/tigo/loading.gif');
      }

      var palette = {
        hover: {
          primary: '#00377D ',
          secondary: '#021b4f'
        },
        font: {
          primary: '#00377D',
          secondary: '#FFFFFF',
          tertiary: '#FFFFFF'
        },
        background: {
          primary: '#FFFFFF',
          secondary: '#00377D'
        },
        button: {
          background: '#FFFFFF'
        }
      };

      var getLoadingColorByPartner = function getLoadingColorByPartner() {
        switch (partner) {
          case 'claro':
            return '#FFFFFF';
          case 'tigo':
            return '#00377D';
          default:
            return '#23BADC';
        }
      };

      return _react2.default.createElement(
        'div',
        { className: 'main-container', style: partner === 'tigo' ? { fontFamily: 'League Spartan, sans-serif' } : { fontFamily: 'Montserrat, sans-serif' } },
        _react2.default.createElement(_reactHelmet2.default, (0, _extends3.default)({}, _config2.default.app.head, { onChangeClientState: function onChangeClientState(newState) {
            return _this2.state.title !== newState.title && _this2.setState({ title: newState.title });
          } })),
        _react2.default.createElement(_reactReduxLoadingBar2.default, { className: _App2.default.loadingBar, style: { backgroundColor: getLoadingColorByPartner() } }),
        !!this.props.loading && _react2.default.createElement(
          'div',
          { className: partner == 'tigo' ? _App2.default.loadingTigoPanel : _App2.default.loadingPanel, key: 'loadingPanelMain' },
          _react2.default.createElement(
            'div',
            { className: partner == 'tigo' ? _App2.default.loadingTigologo : _App2.default.loadinglogo },
            _react2.default.createElement('img', { style: {
                maxWidth: '150px'
              }, src: iconLoading })
          )
        ),
        defaultHeader ? _react2.default.createElement(
          'div',
          { className: partner == 'tigo' ? _App2.default.containerVertical : _App2.default.container },
          partner == 'tigo' ? _react2.default.createElement(_components.VerticalMenu, { palette: palette }) : _react2.default.createElement(_components.MenuContainer, null),
          _react2.default.createElement(
            'div',
            { className: _App2.default.mainContainer },
            _react2.default.createElement(
              'div',
              { className: _App2.default.titleContainer },
              _react2.default.createElement(
                'div',
                { className: _App2.default.title },
                this.state.title ? this.state.title : _react2.default.createElement(_react2.default.Fragment, null)
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _App2.default.main },
              this.props.children
            )
          )
        ) : this.props.children
      );
    }
  }]);
  return App;
}(_react2.default.Component), _class2.propTypes = {
  children: _propTypes2.default.object.isRequired,
  auth: _propTypes2.default.object,
  mobile: _propTypes2.default.object,
  logout: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  loading: _propTypes2.default.any
}, _class2.contextTypes = {
  store: _propTypes2.default.object.isRequired
}, _temp)) || _class);
exports.default = App;
module.exports = exports['default'];