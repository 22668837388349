'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reduxForm = require('redux-form');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Message.scss');

var RemoteSubmitButton = function RemoteSubmitButton(_ref) {
  var dispatch = _ref.dispatch;


  var callForm = function callForm(data) {
    dispatch((0, _reduxForm.submit)('MessageForm'));
  };

  return _react2.default.createElement(
    'div',
    { className: styles.buttonPosition },
    _react2.default.createElement(
      'button',
      {
        type: 'button',
        className: styles.schedule,
        onClick: callForm },
      (0, _translations2.default)('SCHEDULE')
    )
  );
};

exports.default = (0, _reactRedux.connect)()(RemoteSubmitButton);
module.exports = exports['default'];