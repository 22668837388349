'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Loading = require('./Loading.scss');

var _Loading2 = _interopRequireDefault(_Loading);

var _themeEnabled = require('helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var loading = void 0;
var logo = (0, _themeEnabled.themeEnabled)();

if (logo === 'tigo') {
  loading = function loading(_params) {
    return (// IF THEME
      _react2.default.createElement(
        'div',
        { className: _Loading2.default.loadinglogo, key: 'loading-spinner' },
        _react2.default.createElement('img', { alt: 'tigo', className: _Loading2.default.gifTigoLoading, src: (0, _themeEnabled.getAssetLoading)() })
      )
    );
  };
} else if (logo === 'reach') {
  loading = function loading(_params) {
    return (// IF THEME
      _react2.default.createElement(
        'div',
        { className: _Loading2.default.loadinglogo, key: 'loading-spinner' },
        _react2.default.createElement('img', {
          style: {
            maxWidth: '150px'
          },
          src: (0, _themeEnabled.getAssetLoading)(),
          alt: 'reach'
        })
      )
    );
  };
} else {
  loading = function loading(_params) {
    return (// IF THEME
      _react2.default.createElement(
        'div',
        { className: _Loading2.default.loadinglogo, key: 'loading-spinner' },
        _react2.default.createElement('img', { alt: 'claro', src: (0, _themeEnabled.getAssetLoading)() })
      )
    );
  };
}
exports.default = loading;
module.exports = exports['default'];