'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getContactDetail = getContactDetail;
exports.createContact = createContact;
exports.clearContact = clearContact;
exports.uploadContactsFile = uploadContactsFile;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('CONTACT', [_apiAction.ACTION_TYPES.DATA]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getContactDetail(uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/contacts/' + uid);
    }
  };
}

function createContact(form, variables, country_code, msisdn) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/contacts', {
        data: {
          msisdn: msisdn,
          email: form.email,
          variables: variables,
          country_code: country_code || null,
          monitoring: form.monitoring
        }
      });
    }
  };
}

function clearContact() {
  return {
    type: actions.DATA_CLEAR
  };
}

function uploadContactsFile(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/contacts/file', {
        data: data
      });
    }
  };
}