'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _coreDecorators = require('core-decorators');

var _reactSticky = require('react-sticky');

var _themeEnabled = require('helpers/themeEnabled');

var _components = require('components');

var _Balance = require('../../containers/Reports/Balance');

var _Balance2 = _interopRequireDefault(_Balance);

var _List = require('./List.scss');

var _List2 = _interopRequireDefault(_List);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var RECORDS_PER_REQUEST = __DEVELOPMENT__ ? 50 : 100;
var REQUEST_RETRIES = 3;

var List = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(List, _React$Component);

  function List(props) {
    (0, _classCallCheck3.default)(this, List);

    var _this = (0, _possibleConstructorReturn3.default)(this, (List.__proto__ || (0, _getPrototypeOf2.default)(List)).call(this, props));

    _this.state = {
      search: _this.props.location.query.search || '',
      hasMoreRows: true,
      initialLoading: true
    };
    _this.loadingTimeout = null;
    _this.menuType = 'DEFAULT';
    _this.Menu = _components.MenuContainer;
    return _this;
  }

  (0, _createClass3.default)(List, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        if (this.props.refresh) {
          this.props.refresh();
          this.loadMoreRows();
        }
      }

      switch (this.menuType) {
        case 'RESELLER':
          this.Menu = _components.AdminMenuContainer;
          break;
        case 'STAFF':
          this.Menu = _components.StaffMenuContainer;
          break;
        default:
          this.Menu = _components.MenuContainer;
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.loadingTimeout);
    }
  }, {
    key: 'getExtraParams',
    value: function getExtraParams() {
      return {};
    }
  }, {
    key: 'setActive',
    value: function setActive(index, active, event) {
      event.preventDefault();
      if (active) {
        this.props.select(index);
      } else {
        this.props.unselect(index);
      }
    }
  }, {
    key: 'loadMoreRows',
    value: function loadMoreRows() {
      var _this2 = this;

      var retries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : REQUEST_RETRIES;

      var extraParams = this.getExtraParams();
      // this.setState({...this.state, loading: true})
      this.loadingTimeout = setTimeout(function () {
        var search = _this2.state.search;

        if (search && search.indexOf(':') > -1) {
          search = search.split(' ').join('%20');
        }
        var start = _this2.props.list.length;
        var limit = RECORDS_PER_REQUEST;
        if (_this2.props.get && !_this2.props.isLoading && _this2.state.hasMoreRows) {
          return _this2.props.get((0, _extends3.default)({ start: start, limit: limit, search: search }, extraParams)).then(function (list) {
            var hasMoreRows = list.length !== 0;
            _this2.setState((0, _extends3.default)({}, _this2.state, {
              hasMoreRows: hasMoreRows,
              initialLoading: false
            }));
          }).catch(function (err) {
            console.error('::List:loadMoreRows(' + (3 - retries) + ')', err);
            if (retries > 0) {
              setTimeout(function () {
                _this2.loadMoreRows(retries - 1);
              }, 1500);
            } else {
              _this2.setState((0, _extends3.default)({}, _this2.state, {
                hasMoreRows: false,
                initialLoading: false
              }));
            }
          });
        }
      }, 500);
    }
  }, {
    key: 'beginSearch',
    value: function beginSearch() {
      var _this3 = this;

      var search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      this.setState((0, _extends3.default)({}, this.state, {
        search: search,
        hasMoreRows: true
      }), function () {
        _this3.props.refresh();
        _this3.loadMoreRows();
      });
    }
  }, {
    key: 'refreshRows',
    value: function refreshRows() {
      this.props.refresh();
    }
  }, {
    key: 'isSelecting',
    value: function isSelecting() {
      return this.props.selected.length !== 0;
    }
  }, {
    key: 'handleSearch',
    value: function handleSearch(event) {
      if (event && event.key && event.key === 'Enter') {
        if (!this.props.isLoading && !this.props.loading) {
          this.beginSearch(event.target.value);
        }
      } else {
        this.setState((0, _extends3.default)({}, this.state, { search: event.target.value }));
      }
    }
  }, {
    key: 'handleClear',
    value: function handleClear() {
      var _this4 = this;

      this.setState((0, _extends3.default)({}, this.state, { search: '' }));
      this.loadingTimeout = setTimeout(function () {
        _this4.beginSearch();
      }, 500);
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      return _react2.default.createElement('span', null);
    }
  }, {
    key: 'renderFilter',
    value: function renderFilter() {
      return null;
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return null;
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      return null;
    }
  }, {
    key: 'renderTitle',
    value: function renderTitle() {
      return null;
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      return null;
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      return _react2.default.createElement(_Balance2.default, null);
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      return null;
    }
  }, {
    key: 'render',
    value: function render() {
      var Menu = this.Menu;

      if (!Menu) {
        return null;
      }

      if (__SERVER__) {
        return null;
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_List2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _List2.default.listmain },
          this.renderListHeader(),
          _react2.default.createElement(
            'div',
            { key: 'virtual-list-main', className: _List2.default.listscroll },
            this.state.initialLoading ? _react2.default.createElement(_components.VirtualListItemLoad, null) : this.renderInfiniteList()
          )
        )
      );
    }
  }]);
  return List;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool
}, _class2.defaultProps = {
  mobileMode: false
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'setActive', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'setActive'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'loadMoreRows', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'loadMoreRows'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'beginSearch', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'beginSearch'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'refreshRows', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'refreshRows'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'handleSearch', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleSearch'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'handleClear', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleClear'), _class.prototype)), _class);
exports.default = List;
module.exports = exports['default'];