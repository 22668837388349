'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _tags = require('redux/modules/tags');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _auth = require('redux/modules/auth');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _contacts = require('redux/modules/contacts');

var _featureEnabled = require('helpers/featureEnabled');

var _account = require('redux/modules/account');

var _reachHistory = require('../../helpers/reachHistory');

var _Contacts = require('./Contacts.scss');

var _Contacts2 = _interopRequireDefault(_Contacts);

var _themeEnabled = require('helpers/themeEnabled');

var _contact = require('redux/modules/contact');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  person: require('react-icons/lib/md/person'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  personAdd: require('react-icons/lib/md/add'),
  close: require('react-icons/lib/md/clear'),
  tag: require('react-icons/lib/md/label'),
  attach: require('react-icons/lib/md/attach-file'),
  message: require('react-icons/lib/md/email'),
  delete: require('react-icons/lib/md/delete'),
  upload: require('react-icons/lib/md/file-upload')
};

var ContactsUpload = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data._id) {
      return dispatch((0, _account.getAccount)());
    }
  }
}, {
  key: "auth",
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    username: state.auth.data.user.username,
    account: state.account.data
  };
}, {
  push: _reachHistory.push,
  upload: _contact.uploadContactsFile,
  contactsRefresh: _contacts.contactsRefresh,
  createTagsRefactor: _tags.createTagsRefactor,
  getTagsRefactor: _tags.getTagsRefactor,
  showLoading: _reactReduxLoadingBar.showLoading,
  hideLoading: _reactReduxLoadingBar.hideLoading
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(ContactsUpload, _React$Component);

  function ContactsUpload(props) {
    (0, _classCallCheck3.default)(this, ContactsUpload);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactsUpload.__proto__ || (0, _getPrototypeOf2.default)(ContactsUpload)).call(this, props));

    _this.state = (0, _extends3.default)({}, _this.state, {
      selecting: false,
      files: [],
      search: '',
      uploading: false,
      tags: []
    });
    _this.formData = null;
    return _this;
  }

  (0, _createClass3.default)(ContactsUpload, [{
    key: 'onFileAdded',
    value: function onFileAdded(filename, formData) {
      this.formData = formData;
      this.setState((0, _extends3.default)({}, this.state, {
        selecting: false,
        files: [filename]
      }));
    }
  }, {
    key: 'uploadContacts',
    value: function uploadContacts() {
      var _this2 = this;

      this.setState((0, _extends3.default)({}, this.state, {
        uploading: true
      }), function () {
        if (_this2.formData) {
          var tagList = _this2.state.tags.map(function (t) {
            return t.value;
          });
          _this2.formData.append('tags', tagList.join(','));

          _this2.formData.append('created_by', _this2.props.username);
          _this2.props.showLoading();
          _this2.formData.append('country_code', null);

          _this2.props.upload(_this2.formData).then(function () {
            _this2.props.hideLoading();
            _this2.props.contactsRefresh();
            _this2.props.push({
              pathname: '/queued',
              state: {
                goTo: '/contacts/',
                goToModal: false,
                message: (0, _translations2.default)('PROCESSING FILE'),
                modal: true,
                returnTo: '/contacts/'
              }
            });
            setTimeout(function () {
              _this2.props.push({
                pathname: '/contacts/files'
              });
            }, 1000);
            _this2.setState((0, _extends3.default)({}, _this2.state, {
              uploading: false
            }));
          }).catch(function (e) {
            console.error('Error uploading');
            _this2.props.push({
              pathname: '/fail',
              state: {
                goTo: '/contacts/',
                goToModal: false,
                message: (0, _translations2.default)('UPLOAD FILE ERROR') + ' ' + e.message,
                modal: true,
                returnTo: '/contacts/'
              }
            });
            _this2.setState((0, _extends3.default)({}, _this2.state, {
              uploading: false
            }));
          });
        }
      });
    }
  }, {
    key: 'handleType',
    value: function handleType(event) {
      this.setState((0, _extends3.default)({}, this.state, {
        file_type: event.target.value
      }));
    }
  }, {
    key: 'handleTagsChange',
    value: function handleTagsChange(tags) {
      var _this3 = this;

      this.setState((0, _extends3.default)({}, this.state, {
        tags: tags
      }));
      console.log(tags);
      var objetosSinId = [];
      for (var i = 0; i < tags.length; i++) {
        if (!tags[i].hasOwnProperty('id')) {
          objetosSinId.push(tags[i]);
        }
      }
      console.log(objetosSinId);
      objetosSinId.forEach(function (objeto) {
        console.log(objeto.value);
        var dataSend = {
          name: objeto.value,
          enable: true,
          stats: {
            contacts_count: 0,
            conversations_count: 0,
            files_count: 0
          }
        };
        _this3.props.createTagsRefactor(dataSend);
      });
    }
  }, {
    key: 'renderFileSelector',
    value: function renderFileSelector() {
      if (this.props.account.reach == undefined) return _react2.default.createElement(_react2.default.Fragment, null);
      var channels = this.props.account.reach.channels;

      var hasEmail = !!channels.find(function (channel) {
        return channel.type === 'EMAIL' && channel.status === 'ACTIVE';
      });
      var classesTheme = (0, _themeEnabled.themeEnabled)();
      return _react2.default.createElement(
        'div',
        { className: _Contacts2.default.downloadAreaContainer },
        _react2.default.createElement(
          'div',
          { className: _Contacts2.default.linksArea },
          _react2.default.createElement(
            'a',
            { className: _Contacts2.default.downloadTemplate, href: 'https://s3.amazonaws.com/public-im-resources/sms-sample.csv', download: true },
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.clickableElement },
              _react2.default.createElement(
                'div',
                { className: _Contacts2.default.iconContainer },
                _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/download_sms.svg'), className: _Contacts2.default.icon, alt: '' }),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.contactsLabel },
                  (0, _translations2.default)('DOWNLOAD TEMPLATE CONTACTS')
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.label },
                  (0, _translations2.default)('DOWNLOAD SMS TEMPLATE')
                )
              )
            )
          )
        ),
        hasEmail && (0, _featureEnabled.featureEnabled)('channel-email') ? _react2.default.createElement(
          'div',
          { className: _Contacts2.default.linksArea },
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.clickableElement },
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.iconContainer },
              _react2.default.createElement(
                'a',
                { className: _Contacts2.default.downloadTemplate, href: 'https://s3.amazonaws.com/public-im-resources/email-sample.csv', download: true },
                _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/download_email.svg'), className: _Contacts2.default.icon, alt: '' }),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.contactsLabel },
                  (0, _translations2.default)('DOWNLOAD TEMPLATE CONTACTS')
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.label },
                  (0, _translations2.default)('DOWNLOAD EMAIL TEMPLATE')
                )
              )
            )
          )
        ) : null,
        (0, _featureEnabled.featureEnabled)('whatsapp') ? _react2.default.createElement(
          'div',
          { className: _Contacts2.default.linksArea },
          _react2.default.createElement(
            'a',
            { className: _Contacts2.default.downloadTemplate, href: 'https://s3.amazonaws.com/public-im-resources/sms-sample.csv', download: true },
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.clickableElement },
              _react2.default.createElement(
                'div',
                { className: _Contacts2.default.iconContainer },
                _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/whatsapp.svg'), alt: '', className: _Contacts2.default.icon }),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.contactsLabel },
                  (0, _translations2.default)('DOWNLOAD TEMPLATE CONTACTS')
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.label },
                  'Whatsapp'
                )
              )
            )
          )
        ) : null,
        (0, _featureEnabled.featureEnabled)('push') ? _react2.default.createElement(
          'div',
          { className: _Contacts2.default.linksArea },
          _react2.default.createElement(
            'a',
            { className: _Contacts2.default.downloadTemplate, href: 'https://s3.amazonaws.com/public-im-resources/sms-sample.csv', download: true },
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.clickableElement },
              _react2.default.createElement(
                'div',
                { className: _Contacts2.default.iconContainer },
                _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/token_v2.svg'), alt: '', className: _Contacts2.default.icon, fill: '#E5E5E5' }),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.contactsLabel },
                  (0, _translations2.default)('DOWNLOAD TEMPLATE CONTACTS')
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Contacts2.default.label },
                  'Push'
                )
              )
            )
          )
        ) : null
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var selectedClassNames = _Contacts2.default.selected + ' ' + (this.props.mobileMode ? ' ' + _Contacts2.default.mobile : '');
      return _react2.default.createElement(
        'div',
        { className: _Contacts2.default.message + ' ' + _Contacts2.default.uploadContainer },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/contacts/' }),
        _react2.default.createElement(_reactHelmet2.default, { title: "Agregar por archivo" }),
        _react2.default.createElement(
          'div',
          { className: _Contacts2.default.fileBox },
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.downloadArea },
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'h3',
                { className: _Contacts2.default.fileTitle },
                (0, _translations2.default)('STEP 1')
              ),
              _react2.default.createElement(
                'div',
                null,
                (0, _translations2.default)('FILE DOWNLOAD TITLE')
              )
            ),
            this.renderFileSelector()
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.uploadArea },
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'h3',
                { className: _Contacts2.default.fileTitle },
                (0, _translations2.default)('STEP 2')
              ),
              _react2.default.createElement(
                'div',
                null,
                (0, _translations2.default)('FILE UPLOAD TITLE')
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.tagsSelect },
              _react2.default.createElement(_components.TagsSelect, {
                placeholder: (0, _translations2.default)('FILE TAGS'),
                onChange: this.handleTagsChange,
                value: this.state.tags
              })
            ),
            _react2.default.createElement(
              'div',
              { className: _Contacts2.default.dropzone },
              _react2.default.createElement(_components.DropZone, { isModal: true, hideUploadButton: true, onFileAdded: this.onFileAdded, mobileMode: this.props.mobileMode })
            ),
            _react2.default.createElement(
              'button',
              { className: _Contacts2.default.uploadButton, type: 'button', onClick: this.uploadContacts, disabled: this.formData || this.state.uploading ? false : true },
              this.state.uploading ? (0, _translations2.default)('UPLOADING') + '...' : (0, _translations2.default)('UPLOAD CONTACTS FILE')
            )
          )
        )
      );
    }
  }]);
  return ContactsUpload;
}(_react2.default.Component), _class3.propTypes = {
  push: _propTypes2.default.func.isRequired,
  username: _propTypes2.default.string,
  upload: _propTypes2.default.func,
  contactsRefresh: _propTypes2.default.func.isRequired,
  createTagsRefactor: _propTypes2.default.func.isRequired,
  getTagsRefactor: _propTypes2.default.func.isRequired,
  showLoading: _propTypes2.default.func.isRequired,
  hideLoading: _propTypes2.default.func.isRequired,
  account: _propTypes2.default.object.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'onFileAdded', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'onFileAdded'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'uploadContacts', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'uploadContacts'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleType', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleType'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleTagsChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleTagsChange'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = ContactsUpload;
module.exports = exports['default'];